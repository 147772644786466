import React from 'react';

const ImageStats = ({ croppedAreaPixels, zoom, recommendedResolution, debugStats = false }) => {
  return (
    <div className="mt-2 text-left text-gray-700 text-sm">
      {debugStats && (
        <>
          <p><strong>Crop Area (Pixels):</strong> {croppedAreaPixels ? JSON.stringify(croppedAreaPixels) : 'N/A'}</p>
          <p><strong>Zoom Level:</strong> {zoom}</p>
          <p><strong>Crop Position (Pixels):</strong></p>
          <ul>
            <li><strong>X (start):</strong> {croppedAreaPixels ? croppedAreaPixels.x : 'N/A'}</li>
            <li><strong>Y (start):</strong> {croppedAreaPixels ? croppedAreaPixels.y : 'N/A'}</li>
          </ul>
        </>
      )}
      <p className="text-gray-500 mt-2 text-center w-full"><strong>Recommended resolution:</strong> {recommendedResolution}</p>
    </div>
  );
};

export default ImageStats;