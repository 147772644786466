// File: src/components/common/auth-components/LoginComponent.js
import React, { useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { auth } from '../../../firebaseConfig';
import { joinCommunity as joinCommunityAction } from '../../../utils/communityActions';
import logger from '../../../utils/logger';

const LoginComponent = ({ onClose = () => {}, redirectTo }) => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login, googleSignup } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handlePostLoginAction() {
    const postLoginAction = JSON.parse(localStorage.getItem('postLoginAction'));
    const postLoginRedirect = localStorage.getItem('postLoginRedirect') || '/events';

    if (postLoginAction) {
      if (postLoginAction.action === 'join') {
        try {
          const communityId = postLoginAction.communityId;
          const user = auth.currentUser;

          if (!user) throw new Error('User is not authenticated');

          await joinCommunityAction(communityId, user.uid);
        } catch (error) {
          logger.error('Error joining the community:', error);
          setError('Failed to join the community. Please try again later.');
        }
      }
      localStorage.removeItem('postLoginAction');
    }

    // Redirect to the intended page
    navigate(postLoginRedirect);
    localStorage.removeItem('postLoginRedirect');
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      await handlePostLoginAction(); // Handle the post-login action
      onClose(); // Close the modal after login
    } catch (err) {
      logger.error('Login error:', err);
      setError('Failed to log in');
    }

    setLoading(false);
  }

  async function handleGoogleLogin() {
    try {
      setError('');
      setLoading(true);
      await googleSignup(); // Use googleSignup method from AuthContext
      await handlePostLoginAction(); // Handle the post-login action
      onClose(); // Close the modal after login
    } catch (error) {
      logger.error('Google login error:', error.message);
      console.error('Error details:', error);
      setError('Failed to log in with Google');
    }
  
    setLoading(false);
  }

  return (
    <div>
      <h2 className="text-2xl font-bold text-center text-rppPurple mb-4">Login</h2>
      {error && <p className="text-red-500 text-center mb-4">{error}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="email"
          ref={emailRef}
          required
          placeholder="Email"
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
        />
        <input
          type="password"
          ref={passwordRef}
          required
          placeholder="Password"
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
        />
        <button
          disabled={loading}
          type="submit"
          className="w-full bg-purple-700 text-white py-2 rounded-md font-semibold hover:bg-purple-800"
        >
          Login
        </button>
      </form>
      <div className="text-center mt-4">
        <button
          onClick={handleGoogleLogin}
          className="w-full flex items-center justify-center bg-white border-2 border-gray-300 py-2 rounded-md font-semibold hover:bg-gray-100 mb-4"
        >
          <img
            src="https://img.icons8.com/color/16/000000/google-logo.png"
            alt="Google Logo"
            className="mr-2"
          />
          <span className="text-gray-700">Log in with Google</span>
        </button>
        <Link to="/forgot-password" className="text-rppPurple hover:underline">
          Forgot your password?
        </Link>
      </div>
      <div className="text-center mt-4">
        Don't have an account?{' '}
        <Link to="/signup" className="text-rppPurple hover:underline">
          Sign Up
        </Link>
      </div>
      <div className="text-center mt-4">
        <Link to="/" className="text-rppPurple hover:underline">
          Back to Events
        </Link>
      </div>
    </div>
  );
};

export default LoginComponent;