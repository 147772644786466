import { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';
import { db } from '../firebaseConfig';
import logger from '../utils/logger';

const useUpdateProfilePicture = () => {
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);

  const updateProfilePicture = async (downloadURL, currentUser) => {
    if (!currentUser || !currentUser.uid) {
      throw new Error('No authenticated user found.');
    }

    const userId = currentUser.uid;
    setUpdating(true);
    setError(null);

    try {
      // Check if db is initialized correctly
      if (!db) {
        throw new Error('Firestore db is not initialized');
      }
      logger.log('Firestore db instance:', db);

      // Update the user's profile picture URL in Firestore
      const docRef = doc(db, 'members', userId);
      await updateDoc(docRef, { profilePictureUrl: downloadURL });

      // Update the Firebase Auth user profile
      await updateProfile(currentUser, { photoURL: downloadURL });

      logger.log('Profile picture updated successfully');
    } catch (error) {
      logger.error('Error updating profile picture:', error);
      setError('Failed to update profile picture. Please try again.');
    } finally {
      setUpdating(false);
    }
  };

  return { updateProfilePicture, updating, error };
};

export default useUpdateProfilePicture;