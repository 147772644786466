// src/hooks/useUpdateCommunity.js
import { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import logger from '../utils/logger';

const useUpdateCommunity = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateCommunity = async (communityId, data) => {
    setLoading(true);
    try {
      const communityRef = doc(db, 'communities', communityId);
      await updateDoc(communityRef, {
        ...data,  // Spread the data object to update all fields
        updatedAt: new Date(), // Update the timestamp field
      });
      setLoading(false);
    } catch (err) {
      logger.error('Error updating community:', err);
      setError(err);
      setLoading(false);
    }
  };

  return { updateCommunity, loading, error };
};

export default useUpdateCommunity;