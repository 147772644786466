import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { db, storage } from '../../../firebaseConfig';
import { addDoc, doc, updateDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import DateTimeInput from './DateTimeInput';
import DurationInput from './DurationInput';
import HostInfo from './HostInfo';
import CommunitySelect from './CommunitySelect';
import ImageUpload from '../../common/image-editor/ImageUpload';
import StatusSelect from '../../common/form-components/StatusSelect';
import DescriptionEditor from '../../common/form-components/DescriptionEditor';
import OwnerInfo from '../../common/form-components/OwnerInfo';
import TextInput from '../../common/form-components/TextInput';
import logger from '../../../utils/logger';

const EventForm = ({ eventData, hostInfo, ownerInfo, isEditing = false }) => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [communities, setCommunities] = useState([]);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const statusOptions = [
    { value: 'DRAFT', label: 'Draft' },
    { value: 'HIDDEN', label: 'Hidden' },
    { value: 'PRIVATE', label: 'Private' },
    { value: 'PUBLIC', label: 'Public' },
  ];

  useEffect(() => {
    if (isEditing && eventData) {
      setValue('title', eventData.title);
      setValue('dateTime', eventData.dateTime);
      setValue('duration', eventData.duration);
      setValue('status', eventData.status);
      setDescription(eventData.description);
      setSelectedCommunities(eventData.communities || []);
      setImageSrc(eventData.image || null);
    } else {
      const defaultDateTime = () => {
        const now = new Date();
        now.setDate(now.getDate() + 7);
        now.setMinutes(0, 0, 0);
        return now.toISOString().slice(0, 16);
      };

      setValue('dateTime', defaultDateTime());
      setValue('duration', '2 hours'); // Set a default duration value
    }
  }, [isEditing, eventData, setValue]);

  useEffect(() => {
    const fetchCommunities = async () => {
      if (hostInfo) {
        try {
          const q = query(collection(db, 'community_organizers'), where('memberId', '==', hostInfo.memberId));
          const querySnapshot = await getDocs(q);
          const groupIds = querySnapshot.docs.map(doc => doc.data().groupId);

          if (groupIds.length > 0) {
            const communitiesQuery = query(collection(db, 'communities'), where('__name__', 'in', groupIds));
            const communitiesSnapshot = await getDocs(communitiesQuery);
            const communityOptions = communitiesSnapshot.docs.map(doc => ({
              value: doc.id,
              label: doc.data().name,
              ...doc.data(),
            }));
            setCommunities(communityOptions);
          }
        } catch (error) {
          logger.error('Error fetching communities: ', error);
        }
      }
    };

    fetchCommunities();
  }, [hostInfo]);

  const handleImageChange = (imageDataUrl) => {
    setImageSrc(imageDataUrl);
  };

  const handleCropComplete = async (croppedArea, croppedAreaPixels, croppedImage) => {
    setCroppedImage(croppedImage);
  };

  const handleRemoveImage = () => {
    setImageSrc(null);
    setCroppedImage(null);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      let imageUrl = eventData?.image || '';
      if (croppedImage) {
        const storageRef = ref(storage, `images/${croppedImage.name}`);
        const uploadTask = uploadBytesResumable(storageRef, croppedImage);
        await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            null,
            reject,
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                imageUrl = downloadURL;
                resolve();
              }).catch(reject);
            }
          );
        });
      } else if (!imageSrc && eventData?.image) {
        imageUrl = '';
      }

      const eventDocData = {
        ...data,
        image: imageUrl,
        description,
        ownerId: ownerInfo.uid,
        communities: selectedCommunities.map(community => community.value),
        duration: data.duration || '2 hours',
      };

      if (isEditing && eventData?.id) {
        await updateDoc(doc(db, 'events', eventData.id), eventDocData);
        navigate(`/event/${eventData.id}`);
      } else {
        const docRef = await addDoc(collection(db, 'events'), {
          ...eventDocData,
          createdAt: new Date(),
        });
        reset();
        navigate(`/event/${docRef.id}`);
      }
    } catch (error) {
      logger.error('Error adding/updating document:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditorChange = (content) => {
    setDescription(content);
    setValue('description', content);
  };

  const handleCommunityChange = (selectedOptions) => {
    setSelectedCommunities(selectedOptions);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <TextInput
        label="Title"
        name="title"
        register={register}
        required={true}
      />
      <DateTimeInput register={register} />
      <DurationInput register={register} defaultValue={eventData?.duration || '2 hours'} />
      <OwnerInfo ownerInfo={ownerInfo} />
      <HostInfo hostInfo={hostInfo} />
      <CommunitySelect communities={communities} onChange={handleCommunityChange} />
      <ImageUpload
        imageSrc={imageSrc}
        onImageChange={handleImageChange}
        onCropComplete={handleCropComplete}
        onRemoveImage={handleRemoveImage}
      />
      <StatusSelect register={register} options={statusOptions} label="Event Status" />
      <DescriptionEditor value={description} onChange={handleEditorChange} />
      <div className="text-right mt-4">
        <button
          type="submit"
          className="bg-purple-600 text-white font-bold py-2 px-4 rounded-md shadow-md hover:bg-purple-700 transition-colors duration-300"
          disabled={loading}
        >
          {loading ? (isEditing ? 'Updating...' : 'Creating...') : (isEditing ? 'Update Event' : 'Create Event')}
        </button>
      </div>
    </form>
  );
};

export default EventForm;