import React from 'react';
import EventItem from './EventItem';

const EventGroup = ({ item, formatDate, parseDuration }) => {
    return (
        <>
            {item.type === 'month' ? (
                <div className="w-full max-w-4xl my-4 flex flex-col items-center">
                    <span className="text-gray-600 mb-1">{item.value}</span>
                    <div className="w-full border-t border-gray-300"></div>
                </div>
            ) : (
                <EventItem event={item.value} formatDate={formatDate} parseDuration={parseDuration} />
            )}
        </>
    );
};

export default EventGroup;
