import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import useFetchCommunity from '../../../hooks/useFetchCommunity';
import useUserStatus from '../../../hooks/useUserStatus';
import CommunityStatus from './CommunityStatus';
import { FcReading } from 'react-icons/fc';
import CommunityDetailsButtons from './CommunityDetailsButtons';
import CommunityOwner from './CommunityOwner'; // Import the new component
import CommunityRelatedLists from './CommunityRelatedLists';

const CommunityScreen = () => {
  const { id } = useParams();
  const { community, loading, error } = useFetchCommunity(id);
  const { isLoggedIn, isAdmin, isMember, isOwner, refetchUserStatus } = useUserStatus(community); 
  const [memberStatusChanged, setMemberStatusChanged] = useState(false);

  useEffect(() => {
    if (refetchUserStatus) {
      refetchUserStatus();
    }
  }, [memberStatusChanged, refetchUserStatus]);

  const handleMemberChange = () => {
    setMemberStatusChanged((prev) => !prev);
  };

  if (loading) return <p className="text-xl text-purple-600 text-center">Loading...</p>;
  if (error) return <p className="text-xl text-red-600 text-center">{error}</p>;

  return (
    <div className="flex flex-col items-center py-4 px-4 mt-8 w-full">
      <div className="w-full max-w-4xl bg-white border-2 border-purple-600 rounded-lg p-6 shadow-lg mb-4">
        {/* Community Details */}
        {community.imageUrl ? (
          <div className="w-full max-w-md h-auto mx-auto mb-4 border-2 border-gray-300 p-1">
            <img
              src={community.imageUrl}
              alt={community.name}
              className="w-full h-auto object-cover"
            />
          </div>
        ) : (
          <div className="relative w-full max-w-md h-auto bg-gray-200 flex items-center justify-center mx-auto mb-4 border-1 border-gray-300 p-1">
            <div className="relative pb-[56.25%] w-full">
              <FcReading className="absolute inset-0 w-1/2 h-1/2 m-auto" />
            </div>
          </div>
        )}
        <h1 className="text-3xl font-bold text-purple-800 mb-1 text-center">{community.name}</h1>
        <CommunityStatus status={community.status} />
        
        {/* Use the new CommunityOwner component */}
        <CommunityOwner community={community} />

        <div className="text-gray-700 text-lg mb-4 break-words">
          <strong className="font-bold block mb-2">Description:</strong>
          <ReactMarkdown className="space-y-4 break-normal" components={{
            p: ({ node, ...props }) => <div {...props} />, // Replace <p> with <div>
            a: ({ ...props }) => (
              <a
                {...props}
                className="text-purple-600 underline"
                style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}
              >
                {props.children}
              </a>
            ),
          }}>
            {community.description || 'No description provided for this community.'}
          </ReactMarkdown>
        </div>
        <CommunityDetailsButtons
          isLoggedIn={isLoggedIn}
          isAdmin={isAdmin}
          isMember={isMember}
          community={community}
          onMemberChange={handleMemberChange} // Pass the callback function
        />
      </div>
      {/* Render related lists */}
      <CommunityRelatedLists
        groupId={id}
        communityId={id}
        isLoggedIn={isLoggedIn}
        isAdmin={isAdmin}
        isMember={isMember}
        isOwner={isOwner} // Pass the isOwner prop
      />
    </div>
  );
};

export default CommunityScreen;