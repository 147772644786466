import React from 'react';
import ZoomControls from './ZoomControls';

const ImageUploadControls = ({ zoom, handleZoomChange, handleSave, allowSave, localImageSrc, onReset }) => {
    return (
        <div className="mt-4 flex justify-between items-center w-full sticky bottom-0 bg-white p-2">
            {localImageSrc && (
                <div className="flex items-center space-x-2">
                    <ZoomControls zoom={zoom} onZoomChange={handleZoomChange} onReset={onReset} />
                </div>
            )}
            <div className="flex-1"></div>
            {allowSave && (
                <button
                    type="button"
                    onClick={handleSave}
                    className="bg-purple-600 text-white font-bold py-2 px-4 rounded-md shadow-md hover:bg-purple-700 transition-colors duration-300 ml-auto"
                >
                    Save
                </button>
            )}
        </div>
    );
};

export default ImageUploadControls;