import React from 'react';

const FAQ = () => {
    return (
        <div className="flex flex-col items-center justify-start mt-16 px-5 w-full max-w-screen-lg mx-auto">
            <h2 className="text-2xl font-bold text-center text-purple-800 mb-6">Frequently Asked Questions (FAQ)</h2>

            <h3 className="text-xl font-semibold text-purple-800 mb-4">Why did this meetup start?</h3>
            <p className="text-lg text-gray-700 mb-6">
                We created this meetup in November 2019. Our goal was to read books about philosophy and psychology and discuss them together. Along with the goal of making new friends. We started as an in-person meetup, meeting in Manhattan, NYC. When COVID-19 occurred we pivoted to online meetups. Now we run both online and in person. We have also expanded locations to include other cities.
            </p>

            <h3 className="text-xl font-semibold text-purple-800 mb-4">What is the goal of the meetup?</h3>
            <p className="text-lg text-gray-700 mb-6">
                The goal of the meetup is to better understand what the author is saying. It is an act of empathy with the text.
            </p>

            <h3 className="text-xl font-semibold text-purple-800 mb-4">How can I best participate in the meetup?</h3>
            <p className="text-lg text-gray-700 mb-4">
                1. Do The Reading - This Meetup group is a reading group, not a lecture or seminar group. Please read the assigned reading before participating. That is the point of this group, we read then discuss what we read. Sometimes we listen to lectures or videos instead of reading, so when we request you do the reading, it also refers to doing the listening or watching if required. Reading means you read it this week, not years ago. For online Zoom meetings, if you haven’t read we are generally OK if you join and listen in to audit what we are doing. We please ask that if you haven’t read please refrain from participating in the conversation. For in-person meetings we usually limit attendance to those who actually read.
            </p>

            <p className="text-lg text-gray-700 mb-6">
                2. Be Inclusive - We have found that participation by everyone in the meeting who has read creates the most beautiful experience. We would like to include you in the conversation if you have read. More specifics on how you can foster inclusivity can be found <a href="https://docs.google.com/document/d/1AKjIwUaXsITDoXwRsz2Ym9ix9d3VMLe0q9qUlR6qCyE/edit?usp=sharing" target="_blank" rel="noopener noreferrer" className="text-purple-800 hover:underline">here</a>.
            </p>

            <h3 className="text-xl font-semibold text-purple-800 mb-4">How can I give you feedback or contact the organizer?</h3>
            <p className="text-lg text-gray-700 mb-6">
                You can also send the organizer a message on Meetup. We would love to hear from you, especially if you have feedback on the group or a book suggestion or want to collaborate.
            </p>

            <h3 className="text-xl font-semibold text-purple-800 mb-4">What if I have special needs?</h3>
            <p className="text-lg text-gray-700 mb-4">
                If you have special needs, such as physical difficulties, language barriers, mental health conditions, trauma, neurodivergence (e.g. ADHD, autism), social anxiety, or any other condition that may affect your ability to interact comfortably in the group, please don't hesitate to send the organizer a message. We are committed to ensuring that all members feel included and supported.
            </p>
            <p className="text-lg text-gray-700 mb-6">
                By communicating with the organizer, we can work together to tailor our facilitation approach and provide accommodations that meet your specific needs.
            </p>

            <h3 className="text-xl font-semibold text-purple-800 mb-4">Why do some events have a fee?</h3>
            <p className="text-lg text-gray-700 mb-6">
                Most events are free, sometimes an organizer may decide to charge a small fee for an event to recover personal expenses. We do not have any financial backers, related expenses right now are paid for by myself (Ryan) and the individual organizers out of pocket. Sometimes an organizer will charge a fee for the event to recover costs. For example, software licenses, Meetup Pro costs us about $32.50 a month per group (we run 2 x Meetup Pro groups right now) and Zoom is about $57 a month for the version with Webinar and a plugin I (Ryan) use for inclusivity costs $9.99 a month. You can see how the costs may add up. Each organizer may have different costs they are trying to cover, so they may charge a fee. Sometimes the fee may be for their time in reviewing these materials in great detail, or for organizing, or as an honorific.
            </p>

            <h3 className="text-xl font-semibold text-purple-800 mb-4">Are you affiliated with any group, belief system or movement?</h3>
            <p className="text-lg text-gray-700 mb-6">
                No. We are not affiliated with any belief system, political party, religion or movement. We are generally curious and thoughtful people who enjoy reading and the conversations and the insights that come from them.
            </p>

            <h3 className="text-xl font-semibold text-purple-800 mb-4">Are these recorded?</h3>
            <p className="text-lg text-gray-700">
                No recording. Whatever is said at the meetup stays in the meetup.
            </p>
        </div>
    );
};

export default FAQ;