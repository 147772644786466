// src/hooks/useCurrentUserId.js

import { useAuth } from '../contexts/AuthContext';
import { useEffect, useState } from 'react';
import logger from '../utils/logger';

const useCurrentUserId = () => {
  const { currentUser } = useAuth();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (currentUser) {
      setUserId(currentUser.uid);
    } else {
      setUserId(null);
    }
  }, [currentUser]);

  return userId;
};

export default useCurrentUserId;