import React, { useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';

const SignUpComponent = ({ onClose = () => {} }) => {
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const nameRef = useRef();
    const { signup } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    async function handleSubmit(e) {
        e.preventDefault();

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match');
        }

        try {
            setError('');
            setLoading(true);
            await signup(emailRef.current.value, passwordRef.current.value, nameRef.current.value);
            onClose(); // Close the modal after signup
            navigate('/events');
        } catch {
            setError('Failed to create an account');
        }

        setLoading(false);
    }

    return (
        <div>
            <h2 className="text-2xl font-bold text-center rppPurple mb-4">Sign Up</h2>
            {error && <p className="text-red-500 text-center mb-4">{error}</p>}
            <form onSubmit={handleSubmit} className="space-y-4">
                <input
                    type="text"
                    ref={nameRef}
                    required
                    placeholder="Your Name"
                    name="name"
                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
                    autocomplete="name"
                />
                <input
                    type="email"
                    ref={emailRef}
                    required
                    placeholder="Email"
                    name="email"
                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
                    autocomplete="email"
                />
                <input
                    type="password"
                    ref={passwordRef}
                    required
                    placeholder="Password"
                    name="new-password"
                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
                    autocomplete="new-password"
                />
                <input
                    type="password"
                    ref={passwordConfirmRef}
                    required
                    placeholder="Confirm Password"
                    name="confirm-password"
                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
                    autocomplete="new-password"
                />
                <button
                    disabled={loading}
                    type="submit"
                    className="w-full bg-purple-700 text-white py-2 rounded-md font-semibold hover:bg-purple-800"
                >
                    Sign Up
                </button>
            </form>
            <div className="text-center mt-4">
                Already have an account?{' '}
                <Link to="/login" className="rppPurple hover:underline">
                    Log In
                </Link>
            </div>
            <div className="text-center mt-4">
                <Link to="/" className="rppPurple hover:underline">
                    Back to Events
                </Link>
            </div>
        </div>
    );
};

export default SignUpComponent;