import React from 'react';
import { FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import useFetchMembers from '../../../hooks/useFetchMembers';
import logger from '../../../utils/logger';
import LoadingSpinner from '../../common/LoadingSpinner';

const CommunityMembers = ({ communityId }) => {
  const { members, loading, error } = useFetchMembers(communityId);
  const navigate = useNavigate(); // Initialize useNavigate

  if (loading) return <LoadingSpinner/>;

  // Check if the user has permission to view members or if an error occurred
  if (error) {
    logger.error(error);
    return null; // Do not render anything if there's an error
  }

  // If no members found or user is not authorized to view them
  if (members.length === 0) return null;

  // Handler for navigating to member details
  const handleMemberClick = (memberId) => {
    navigate(`/member/${memberId}`);
  };

  return (
    <ul>
      {members.map((member) => (
        <li
          key={member.memberId}
          className="flex items-center py-4 border-b border-gray-200 last:border-0 px-4"
        >
          {member.profilePictureUrl ? (
            <img
              src={member.profilePictureUrl}
              alt={member.displayName}
              className="w-10 h-10 rounded-full mr-4 cursor-pointer"
              onClick={() => handleMemberClick(member.memberId)} // Make the image clickable
            />
          ) : (
            <FaUser
              className="w-10 h-10 rounded-full mr-4 text-gray-500 cursor-pointer"
              onClick={() => handleMemberClick(member.memberId)} // Make the icon clickable
            />
          )}
          <span
            className="flex-1 text-gray-700 cursor-pointer hover:underline"
            onClick={() => handleMemberClick(member.memberId)} // Make the name clickable
          >
            {member.displayName}
          </span>
          <span className="text-gray-500">
            {member.role || 'No role'}  {/* Add a fallback if role is missing */}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default CommunityMembers;