// /src/pages/Login.js
import React from 'react';
import LoginComponent from '../../common/auth-components/LoginComponent';

const Login = () => {
    return (
        <div className="flex flex-col items-center justify-start min-h-screen" style={{ backgroundColor: 'rgb(240, 240, 240)' }}>
            <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-md mt-10">
                <LoginComponent />
            </div>
        </div>
    );
};

export default Login;