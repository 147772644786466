import React from 'react';
import { Link } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import { FcReading } from 'react-icons/fc';
import CommunityStatus from '../communities/CommunityStatus';
import useFetchMember from '../../../hooks/useFetchMember';

const CommunityListItem = ({ community }) => {
  const isMeetup = community.status === 'MEETUP.COM';

  // Only fetch member if not a Meetup community
  const { member, loading: memberLoading, error: memberError } = isMeetup
    ? { member: null, loading: false, error: null }
    : useFetchMember(community.ownerId);

  const organizerName = isMeetup ? community.organizerName : member?.displayName || 'Not provided';

  return (
    <div className="w-full mb-6">
      <div className="bg-white border-2 border-purple-600 rounded-lg p-6 shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col lg:flex-row items-start transition-transform duration-300 hover:scale-102 hover:shadow-xl max-w-4xl mx-auto">
        
        {/* Link wrapping the image */}
        <Link to={`/community/${community.id}`} className="lg:w-1/3 self-start mb-4 lg:mb-0 lg:mr-4 no-underline">
          {community.imageUrl ? (
            <img src={community.imageUrl} alt={community.name} className="w-full h-auto rounded-md object-cover" />
          ) : (
            <div className="relative w-full h-0 pb-[56.25%] rounded-md bg-gray-200 flex items-center justify-center">
              <FcReading className="text-6xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
            </div>
          )}
        </Link>
        
        <div className="flex-1">
          {/* Link wrapping only the title */}
          <Link to={`/community/${community.id}`} className="no-underline">
            <h2 className="text-2xl font-bold text-purple-800 mb-4 text-center lg:text-left">{community.name}</h2>
          </Link>
          <div className="text-gray-700 mb-4">
            <strong className="font-bold">Location:</strong> {community.location || 'Not provided'}
          </div>
          <CommunityStatus status={community.status || 'Not Specified'} />
          <div className="text-gray-700 mb-4 flex items-center">
            <strong className="font-bold mr-2">Owner:</strong>
            {isMeetup ? (
              <div className="flex items-center">
                <FaUserCircle className="w-8 h-8 mr-2 text-gray-400" />
                {organizerName}
              </div>
            ) : memberLoading ? (
              <p>Loading owner...</p>
            ) : memberError ? (
              <p>Error loading owner</p>
            ) : (
              <Link to={`/member/${community.ownerId}`} className="flex items-center no-underline">
                {member?.profilePictureUrl ? (
                  <img src={member.profilePictureUrl} alt={organizerName} className="w-8 h-8 rounded-full mr-2" />
                ) : (
                  <FaUserCircle className="w-8 h-8 mr-2 text-gray-400" />
                )}
                <span className="hover:underline">{organizerName}</span>
              </Link>
            )}
          </div>
          <div className="text-right">
            {/* Link wrapping only the button */}
            <Link to={`/community/${community.id}`} className="no-underline">
              <span className="inline-block bg-white border-2 border-purple-600 text-purple-600 py-2 px-4 rounded-md transition-colors duration-300 hover:bg-purple-600 hover:text-white">
                View Community
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityListItem;