// /src/pages/SignUp.js
import React from 'react';
import SignUpComponent from '../../common/auth-components/SignUpComponent';

const SignUp = () => {
    return (
        <div className="flex flex-col items-center justify-start min-h-screen" style={{ backgroundColor: 'rgb(240, 240, 240)' }}>
            <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-md mt-10">
                <SignUpComponent />
            </div>
        </div>
    );
};

export default SignUp;