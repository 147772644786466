import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa'; // Import the user icon for fallback
import useFetchMember from '../../../hooks/useFetchMember';
import Attendance from '../../common/attendance/Attendance';
import logger from '../../../utils/logger';

const MemberDetails = () => {
    const { id } = useParams();
    const { member, loading, error } = useFetchMember(id);

    if (loading) return <p className="text-xl text-center">Loading...</p>;
    if (error) return <p className="text-xl text-red-600 text-center">Error: {error}</p>;
    if (!member) return <p className="text-xl text-red-600 text-center">Member not found</p>;

    return (
        <div className="flex flex-col items-center mt-8">
            <div className="w-full max-w-3xl bg-white shadow-md rounded-lg p-6 mb-8">
                <div className="relative w-24 h-24 mx-auto mb-6">
                    {member.profilePictureUrl ? (
                        <img
                            src={member.profilePictureUrl}
                            alt={member.displayName || 'Member'}
                            className="w-full h-full rounded-full object-cover border-2 border-gray-400"
                        />
                    ) : (
                        <FaUserCircle className="w-full h-full rounded-full text-gray-400" />
                    )}
                </div>
                <h1 className="text-3xl font-bold text-center text-purple-700 mb-4">{member.displayName || 'Member'}</h1>
            </div>
            <Attendance memberId={id} />
        </div>
    );
};

export default MemberDetails;