import useCurrentUserId from './useCurrentUserId'; // Import the hook to get the current user's ID
import useFetchMember from './useFetchMember'; // Import the generic hook to fetch member details

const useFetchCurrentUserMember = () => {
  const userId = useCurrentUserId(); // Get the current user's ID

  // Only call useFetchMember if userId is defined
  const { member, loading, error, refetch } = useFetchMember(userId);

  return { member, loading: loading || !userId, error, refetch }; // Return loading as true if userId is not yet available
};

export default useFetchCurrentUserMember;