// src/components/EventDetails.js

import React from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Attendance from '../../../components/common/attendance/Attendance';
import ReusableButton from '../../common/reusable-button/ReusableButton';
import { useAuth } from '../../../contexts/AuthContext';
import useFetchEvent from '../../../hooks/useFetchEvent';
import { parseDuration } from '../../../utils/utils'; // Adjusted import path

const EventDetails = () => {
    const { id } = useParams();
    const { eventData: event, loading, error } = useFetchEvent(id);
    const { currentUser, isAdmin } = useAuth();

    if (loading) return <p className="text-xl text-purple-600">Loading...</p>;
    if (error) return <p className="text-xl text-red-600">{error}</p>;
    if (!event) return <p className="text-xl text-red-600">Event not found</p>;

    return (
        <div className="flex flex-col items-center py-0 px-0 mt-0 md:py-4 md:px-4 md:mt-4 pb-20 md:pb-24">
            <div className="w-full max-w-4xl bg-white border-0 md:border-2 border-purple-600 rounded-none md:rounded-lg p-0 md:p-6 shadow-none md:shadow-lg">
                {event.meetupImage && (
                    <img
                        src={event.meetupImage}
                        alt={event.title}
                        className="w-full h-auto rounded-none mb-0 object-cover mx-auto md:rounded-md md:mb-4"
                    />
                )}
                {event.image && isAdmin && (
                    <div className="my-4">
                        <img
                            src={event.image}
                            alt={event.title}
                            className="w-full h-auto rounded-none mb-0 object-cover mx-auto md:rounded-md md:mb-4"
                        />
                    </div>
                )}
                <h1 className="text-3xl font-bold text-purple-800 mb-4 text-center mt-4 md:mt-6">{event.title}</h1>
                <div className="text-gray-700 text-lg mb-2 grid grid-cols-1 md:grid-cols-2 gap-2 p-4 md:p-0">
                    <div>
                        <strong className="font-bold">Date & Time:</strong> {new Date(event.dateTime).toLocaleString()}
                    </div>
                    <div>
                        <strong className="font-bold">Host:</strong> {event.hostName}
                    </div>
                    <div>
                        <strong className="font-bold">Duration:</strong> {parseDuration(event.duration)}
                    </div>
                    <div>
                        <strong className="font-bold">Group: </strong>
                        <Link to={`/community/${event.groupId}`} className="text-black hover:underline ml-1">
                            {event.groupName}
                        </Link>
                    </div>
                </div>
                <div className="text-gray-700 text-lg mb-4 break-words px-4 md:px-0">
                <strong className="font-bold block mb-2">Description:</strong>
                <ReactMarkdown className="space-y-4 break-normal" components={{
                    a: ({ ...props }) => (
                        <a
                            {...props}
                            className="text-purple-600 underline"
                            style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}
                        >
                            {props.children}
                        </a>
                    ),
                }}>
                    {event.description}
                </ReactMarkdown>
                </div>
                <div className="fixed bottom-0 left-0 w-full bg-white p-4 shadow-lg flex justify-end space-x-4 z-50 border-t rppBorderGrey">
                    {currentUser && currentUser.uid === event.ownerId && (
                        <Link to={`/edit-event/${id}`} className="inline-block w-1/2">
                            <ReusableButton className="w-full">
                                Edit Event
                            </ReusableButton>
                        </Link>
                    )}
                    <ReusableButton href={event.eventUrl} className="w-1/2">
                        Attend on Meetup
                    </ReusableButton>
                </div>
            </div>
            <Attendance eventId={id} />
        </div>
    );
};

export default EventDetails;