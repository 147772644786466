import React, { useState } from 'react';
import useFetchCommunities from '../../../hooks/useFetchCommunities';
import CommunityScreenButtons from './CommunityScreenButtons';
import CommunityListItem from './CommunityListItem';
import logger from '../../../utils/logger';

const CommunityList = () => {
  logger.log("CommunityList component rendered");

  const [activeTab, setActiveTab] = useState('all-communities'); // Default to 'all'

  // Map activeTab to the expected communityType in useFetchCommunities
  const communityTypeMap = {
    'all': 'all',
    'all-communities': 'all',
    'my-communities': 'owned',
    'public': 'public',
  };

  const { communities, loading, error } = useFetchCommunities(communityTypeMap[activeTab]);

  logger.log("We reached CommunityList...");

  return (
    <div className="flex flex-col items-center py-4 w-full">
      <div className="flex justify-end w-full max-w-4xl mb-4">
        <CommunityScreenButtons activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      {loading && <p className="text-xl text-purple-600">Loading...</p>}
      {error && <p className="text-xl text-red-600">Error: {error}</p>}
      {!loading && !error && communities.length > 0 ? (
        communities.map((community, index) => (
          <CommunityListItem key={index} community={community} />
        ))
      ) : (
        !loading && <p className="text-xl text-purple-600">No communities available</p>
      )}
    </div>
  );
};

export default CommunityList;