// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getFirestore, setLogLevel, connectFirestoreEmulator } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getPerformance } from "firebase/performance";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import logger from "./utils/logger";

// Log all process environment variables for debugging
logger.log('Process Env:', process.env);

// Log the custom environment variable to verify the environment
logger.log('REACT_APP_ENV:', process.env.REACT_APP_ENV);

// Log environment variables for debugging
logger.log("Firebase Config:", {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);

// Initialize Performance Monitoring
const perf = getPerformance(app);

// Connect to Firebase emulators if in local development
if (process.env.REACT_APP_ENV === 'local') {
  logger.log("Connecting to Firebase emulators...");

  const firestoreHost = process.env.REACT_APP_FIRESTORE_EMULATOR_HOST.split(':');
  const authHost = process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST.split(':');
  const storageHost = process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_HOST.split(':');
  const functionsHost = process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST.split(':');

  logger.log("Firestore Emulator Host:", firestoreHost);
  logger.log("Auth Emulator Host:", authHost);
  logger.log("Storage Emulator Host:", storageHost);
  logger.log("Functions Emulator Host:", functionsHost);

  connectFirestoreEmulator(db, firestoreHost[0], Number(firestoreHost[1]));
  connectAuthEmulator(auth, `http://${authHost[0]}:${authHost[1]}`);
  connectStorageEmulator(storage, storageHost[0], Number(storageHost[1]));
  connectFunctionsEmulator(functions, functionsHost[0], Number(functionsHost[1]));
} else {
  logger.log("Connecting to live Firebase services...");
}

export { db, analytics, auth, storage, functions };