import React, { useEffect, useState } from 'react';
import { db } from '../../../firebaseConfig';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';

const Attendance = ({ eventId, memberId }) => {
    const { isAdmin } = useAuth();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (eventId) {
                    const attendanceRef = collection(db, 'attendance');
                    const q = query(attendanceRef, where("eventId", "==", eventId));
                    const querySnapshot = await getDocs(q);

                    if (!querySnapshot.empty) {
                        const attendeesData = [];

                        for (const docSnap of querySnapshot.docs) {
                            const attendeeData = docSnap.data();
                            const memberRef = collection(db, 'members');
                            const memberQuery = query(memberRef, where("memberId", "==", attendeeData.memberId));
                            const memberSnapshot = await getDocs(memberQuery);

                            if (!memberSnapshot.empty) {
                                const memberData = memberSnapshot.docs[0].data();
                                attendeesData.push({ ...attendeeData, member: memberData });
                            } else {
                                attendeesData.push(attendeeData);
                            }
                        }

                        setData(attendeesData);
                    } else {
                        setError('No attendance data found for this event.');
                    }
                } else if (memberId) {
                    const attendanceRef = collection(db, 'attendance');
                    const q = query(attendanceRef, where("memberId", "==", memberId));
                    const querySnapshot = await getDocs(q);

                    if (!querySnapshot.empty) {
                        const eventsData = [];

                        for (const docSnap of querySnapshot.docs) {
                            const attendanceData = docSnap.data();
                            const eventRef = doc(db, 'events', attendanceData.eventId);
                            const eventSnapshot = await getDoc(eventRef);

                            if (eventSnapshot.exists()) {
                                const eventData = eventSnapshot.data();
                                eventsData.push({ ...attendanceData, event: eventData });
                            } else {
                                eventsData.push({ ...attendanceData, event: {} });
                            }
                        }

                        eventsData.sort((a, b) => new Date(b.event?.dateTime || 0) - new Date(a.event?.dateTime || 0));
                        setData(eventsData);
                    } else {
                        setError('No attendance data found for this member.');
                    }
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (isAdmin) {
            fetchData();
        }
    }, [eventId, memberId, isAdmin]);

    if (!isAdmin) return null;
    if (loading) return <p className="text-xl text-purple-600">Loading data...</p>;

    return (
        <div className="mt-8 bg-white border-2 border-purple-600 rounded-lg shadow-lg w-full max-w-4xl">
            <div className="bg-black text-white p-4 rounded-t-lg mb-4 flex justify-center">
                <h2 className="text-xl font-bold">{eventId ? 'Attendance' : 'Events Attended'}</h2>
            </div>
            <ul className="list-none p-0 m-0">
                {error ? (
                    <p className="text-red-600 p-4">{error}</p>
                ) : (
                    data.length === 0 ? (
                        <p className="text-gray-600 p-4">{eventId ? 'No attendees found.' : 'No events found.'}</p>
                    ) : (
                        data.map((item, index) => (
                            <li key={index} className="flex items-center py-4 border-b border-gray-200 last:border-0 px-4">
                                {eventId ? (
                                    item.member ? (
                                        <Link to={`/member/${item.member.memberId}`} className="flex items-center w-full no-underline hover:bg-gray-100 transition-colors duration-300 p-2 rounded-lg">
                                            {item.member.memberPhoto && (
                                                <img src={item.member.memberPhoto} alt={item.member.name} className="w-10 h-10 rounded-full mr-4" />
                                            )}
                                            <span className="flex-1 text-gray-700">{item.member.name}</span>
                                            <span className="flex-1 text-gray-700">{item.memberId}</span>
                                            <span className="flex-1 text-gray-700">{item.status}</span>
                                        </Link>
                                    ) : (
                                        <>
                                            <span className="flex-1 text-gray-700">{item.memberId}</span>
                                            <span className="flex-1 text-gray-700">{item.status}</span>
                                        </>
                                    )
                                ) : (
                                    item.event ? (
                                        <Link to={`/event/${item.event.eventId || item.eventId}`} className="flex items-center w-full no-underline hover:bg-gray-100 transition-colors duration-300 p-2 rounded-lg">
                                            <span className="flex-1 text-gray-700">{item.eventId}</span>
                                            <span className="flex-1 text-gray-700">{item.event.title || '-'}</span>
                                            <span className="flex-1 text-gray-700">{item.status}</span>
                                            <span className="flex-1 text-gray-700">{item.event.dateTime ? new Date(item.event.dateTime).toLocaleString() : 'Invalid Date'}</span>
                                            <span className="flex-1 text-gray-700">{item.event.hostName || 'Unknown Host'}</span>
                                        </Link>
                                    ) : (
                                        <>
                                            <span className="flex-1 text-gray-700">{item.eventId}</span>
                                            <span className="flex-1 text-gray-700">{item.status}</span>
                                        </>
                                    )
                                )}
                            </li>
                        ))
                    )
                )}
            </ul>
        </div>
    );
};

export default Attendance;