import React from 'react';

const ImageUploadButton = ({ handleImageChange }) => {
    return (
        <div className="relative w-full h-0 min-h-[10rem] flex items-center justify-center rounded-md text-center">
            <input
                type="file"
                accept="image/*"
                className="hidden"
                id="file-upload"
                onChange={handleImageChange}
            />
            <button
                type="button"
                onClick={() => document.getElementById('file-upload').click()}
                className="bg-purple-600 text-white font-bold py-2 px-4 rounded-md shadow-md hover:bg-purple-700 transition-colors duration-300 absolute inset-0 m-auto"
                style={{ width: 'fit-content', height: 'fit-content' }}
            >
                Upload Photo
            </button>
        </div>
    );
};

export default ImageUploadButton;