import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <div className="flex flex-col items-center justify-start mt-16 px-5 w-full max-w-screen-lg mx-auto">
            <h2 className="text-2xl font-bold text-center text-purple-800 mb-5">About Us</h2>
            <p className="text-lg text-gray-700 mb-4">
                Welcome to the Reading Philosophy and Psychology Circle! Our mission is to bring together individuals who are passionate about reading and discussing topics in philosophy and psychology. We host a variety of events each week in multiple cities, providing a space for intellectual engagement and social connection.
            </p>
            <p className="text-lg text-gray-700 mb-4">
                Our club is organized by dedicated volunteers who work tirelessly to create a welcoming and enriching environment for all members. By becoming a member, you help us cover operational costs and support our volunteers, ensuring the continued success and growth of our community.
            </p>
            <p className="text-lg text-gray-700 mb-4">
                For more information, please see our <Link to="/faq" className="text-purple-800 hover:underline">FAQ</Link>.
            </p>
            <p className="text-lg text-gray-700">
                Thank you for your interest in our club. We look forward to seeing you at our next event!
            </p>
        </div>
    );
};

export default About;