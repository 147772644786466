import { useState, useEffect, useCallback } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import logger from '../utils/logger';

/**
 * Custom hook to check and manage the user's status within a specific community.
 *
 * @param {Object} community - Community object
 * @returns {Object} User status and refetch function
 */
const useUserStatus = (community) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  /**
   * Function to check and update the user status.
   */
  const checkUserStatus = useCallback(async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      setIsLoggedIn(true);
      const userId = user.uid;
      try {
        const idTokenResult = await user.getIdTokenResult();
        setIsAdmin(!!idTokenResult.claims.admin);
        logger.log("Admin status:", !!idTokenResult.claims.admin);
      
        if (community) {
          logger.log("Checking community ownership...");
          setIsOwner(community.ownerId === userId);
          logger.log("Is Owner:", community.ownerId === userId);
      
          logger.log("Fetching community member connection...");
          const memberRef = doc(db, 'community_members', `${community.id}_${userId}`);
          const memberSnapshot = await getDoc(memberRef);
          logger.log("Member snapshot exists:", memberSnapshot.exists());
          setIsMember(memberSnapshot.exists());
        }
      } catch (error) {
        if (error.code === 'permission-denied') {
          logger.log('User does not have permission to access the community_members document.');
          setIsMember(false); // Assume the user is not a member if they lack permission
        } else {
          logger.error('Unexpected error:', error);
          // Handle other types of errors appropriately if necessary
        }
      }
    } else {
      setIsLoggedIn(false);
    }
  }, [community]);

  useEffect(() => {
    if (community) {
      checkUserStatus();
    }
  }, [community, checkUserStatus]);

  return { isLoggedIn, isAdmin, isOwner, isMember, refetchUserStatus: checkUserStatus };
};

export default useUserStatus;
