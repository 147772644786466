import React from 'react';

const HostInfo = ({ hostInfo }) => (
    <div>
        <label className="block text-lg font-bold mb-2">Host</label>
        <div className="w-full p-2 border border-gray-300 rounded-md bg-gray-100">
            {hostInfo ? (
                <div className="flex items-center">
                    <img
                        src={hostInfo.memberPhoto}
                        alt={hostInfo.name}
                        className="w-8 h-8 rounded-full mr-2"
                    />
                    <span>{hostInfo.name}</span>
                </div>
            ) : (
                <p>Loading host information...</p>
            )}
        </div>
    </div>
);

export default HostInfo;
