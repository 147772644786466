import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../../firebaseConfig';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { useAuth } from '../../../contexts/AuthContext';
import { FaThumbsUp, FaUserCircle, FaComment, FaEdit, FaTrash } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReusableButton from '../../common/reusable-button/ReusableButton';
import { useNavigate } from 'react-router-dom';

const Suggestions = ({ communityId }) => {
    const { currentUser } = useAuth();
    const [suggestions, setSuggestions] = useState([]);
    const [newSuggestion, setNewSuggestion] = useState('');
    const [isExpanded, setIsExpanded] = useState(false); // State to manage expanded view
    const [editingSuggestionId, setEditingSuggestionId] = useState(null);
    const [editingSuggestionText, setEditingSuggestionText] = useState('');
    const suggestionBoxRef = useRef(null); // Ref to track the suggestion box element
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSuggestions = async () => {
            const querySnapshot = await getDocs(collection(db, 'suggestions'));
            const suggestionsData = querySnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    createdAt: data.createdAt.toDate() // Convert Firestore timestamp to JS Date object
                };
            });
            // Sort suggestions by votes in descending order
            // Filter suggestions based on the provided communityId
            const filteredSuggestions = suggestionsData.filter(s => s.communityId === communityId);
            const sortedSuggestions = filteredSuggestions.sort((a, b) => b.votes - a.votes);
            setSuggestions(sortedSuggestions);
        };

        fetchSuggestions();
    }, []);

    useEffect(() => {
        // Close the suggestion box if clicked outside
        const handleClickOutside = (event) => {
            if (suggestionBoxRef.current && !suggestionBoxRef.current.contains(event.target)) {
                setIsExpanded(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [suggestionBoxRef]);

    const handleUpvote = async (suggestionId) => {
        if (!currentUser) return alert('You must be logged in to vote.');

        const suggestionDoc = doc(db, 'suggestions', suggestionId);
        const suggestion = suggestions.find(s => s.id === suggestionId);
        const hasUpvoted = suggestion.upvotedBy?.includes(currentUser.uid);

        let newVotes;
        let newUpvotedBy;

        if (hasUpvoted) {
            newVotes = suggestion.votes - 1;
            newUpvotedBy = suggestion.upvotedBy.filter(uid => uid !== currentUser.uid);
        } else {
            newVotes = suggestion.votes + 1;
            newUpvotedBy = [...(suggestion.upvotedBy || []), currentUser.uid];
        }

        await updateDoc(suggestionDoc, { votes: newVotes, upvotedBy: newUpvotedBy });
        setSuggestions(suggestions.map(s => s.id === suggestionId ? { ...s, votes: newVotes, upvotedBy: newUpvotedBy } : s));
    };

    const handleAddSuggestion = async () => {
        if (!currentUser) return alert('You must be logged in to add a suggestion.');

        const newDoc = await addDoc(collection(db, 'suggestions'), {
            text: newSuggestion,
            votes: 0,
            upvotedBy: [], // Initialize the upvotedBy array
            comments: [], // Initialize the comments array
            userId: currentUser.uid,
            username: currentUser.displayName || 'Anonymous', // Ensure you capture the display name
            userProfilePic: currentUser.photoURL || '', // Capture the profile picture URL
            createdAt: new Date(),
            communityId
        });

        const newSuggestions = [...suggestions, { id: newDoc.id, text: newSuggestion, votes: 0, upvotedBy: [], comments: [], userId: currentUser.uid, username: currentUser.displayName || 'Anonymous', userProfilePic: currentUser.photoURL || '', createdAt: new Date(), communityId }];
        // Sort new suggestions list by votes in descending order
        setSuggestions(newSuggestions.sort((a, b) => b.votes - a.votes));
        setNewSuggestion('');
        setIsExpanded(false); // Collapse the box after submission
    };

    const handleSuggestionClick = (suggestionId) => {
        navigate(`/suggestions/${suggestionId}`);
    };

    const handleEditSuggestion = (suggestionId, text) => {
        setEditingSuggestionId(suggestionId);
        setEditingSuggestionText(text);
    };

    const handleSaveEditedSuggestion = async (suggestionId) => {
        if (!currentUser) return alert('You must be logged in to edit.');

        const suggestionDoc = doc(db, 'suggestions', suggestionId);
        await updateDoc(suggestionDoc, { text: editingSuggestionText });
        
        setSuggestions(suggestions.map(s => s.id === suggestionId ? { ...s, text: editingSuggestionText } : s));
        setEditingSuggestionId(null);
        setEditingSuggestionText('');
    };

    const handleDeleteSuggestion = async (suggestionId) => {
        if (!currentUser) return alert('You must be logged in to delete.');

        const suggestionDoc = doc(db, 'suggestions', suggestionId);
        await deleteDoc(suggestionDoc);

        setSuggestions(suggestions.filter(s => s.id !== suggestionId));
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold text-purple-800 mb-6 text-center">Suggestions</h1>
            <div className="w-full max-w-4xl mx-auto bg-white border-2 border-purple-600 rounded-lg p-6 shadow-lg mb-6" ref={suggestionBoxRef}>
                <h2 className="text-2xl font-bold text-purple-800 mb-4">Add a New Suggestion</h2>
                {isExpanded ? (
                    <>
                        <div className="mb-4">
                            <ReactQuill
                                value={newSuggestion}
                                onChange={setNewSuggestion}
                                className="w-full h-32"
                                style={{ fontSize: '16px' }} // Set inline style for font size
                            />
                        </div>
                        <div className="flex justify-end pt-10">
                            <div className="flex-shrink-0 pt-6">
                                <ReusableButton onClick={handleAddSuggestion}>
                                    Add Suggestion
                                </ReusableButton>
                            </div>
                        </div>
                    </>
                ) : (
                    <div
                        className="cursor-pointer p-2 border rounded-md text-gray-500 hover:text-gray-700"
                        onClick={() => setIsExpanded(true)}
                    >
                        Click to add a new suggestion...
                    </div>
                )}
            </div>
            <div className="w-full max-w-4xl mx-auto bg-white border-2 border-purple-600 rounded-lg p-6 shadow-lg">
                <h2 className="text-2xl font-bold text-purple-800 mb-4">Current Suggestions</h2>
                {suggestions.map((suggestion) => (
                    <div key={suggestion.id} className="mb-4 border-b border-gray-200 pb-4">
                        {editingSuggestionId === suggestion.id ? (
                            <>
                                <ReactQuill
                                    value={editingSuggestionText}
                                    onChange={setEditingSuggestionText}
                                    className="w-full h-32"
                                />
                                <div className="flex justify-end mt-2 pt-10">
                                    <ReusableButton onClick={() => handleSaveEditedSuggestion(suggestion.id)}>
                                        Save
                                    </ReusableButton>
                                </div>
                            </>
                        ) : (
                            <div onClick={() => handleSuggestionClick(suggestion.id)} className="cursor-pointer flex justify-between items-center">
                                <div>
                                    <div className="font-semibold" dangerouslySetInnerHTML={{ __html: suggestion.text }} />
                                    <div className="flex items-center text-sm text-gray-500">
                                        {suggestion.userProfilePic ? (
                                            <img src={suggestion.userProfilePic} alt="Profile" className="w-6 h-6 rounded-full mr-2" />
                                        ) : (
                                            <FaUserCircle className="w-6 h-6 mr-2" />
                                        )}
                                        Suggested by {suggestion.username} on {new Date(suggestion.createdAt).toLocaleString()}
                                    </div>
                                </div>
                                <div className="flex items-center space-x-4">
                                    <button onClick={(e) => { e.stopPropagation(); handleUpvote(suggestion.id); }} className={`flex items-center px-4 py-2 rounded-md transition duration-200 ${suggestion.upvotedBy?.includes(currentUser?.uid) ? 'bg-purple-700 text-white' : 'bg-purple-600 text-white hover:bg-purple-700'}`}>
                                        <FaThumbsUp className="mr-2" /> {suggestion.votes}
                                    </button>
                                    <div className="flex items-center text-gray-600">
                                        <FaComment className="mr-2" />
                                        {suggestion.comments.length}
                                    </div>
                                    {suggestion.userId === currentUser?.uid && (
                                        <div className="flex items-center space-x-2">
                                            <FaEdit className="text-blue-500 cursor-pointer" onClick={(e) => { e.stopPropagation(); handleEditSuggestion(suggestion.id, suggestion.text); }} />
                                            <FaTrash className="text-red-500 cursor-pointer" onClick={(e) => { e.stopPropagation(); handleDeleteSuggestion(suggestion.id); }} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Suggestions;
