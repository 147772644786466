// src/components/DurationInput.js

import React from 'react';

const DurationInput = ({ register }) => (
    <div>
        <label className="block text-lg font-bold mb-2">Duration</label>
        <select
            {...register('duration', { required: true })}
            defaultValue="2 hours" // Set a default value
            className="w-full p-2 border border-gray-300 rounded-md"
        >
            <option value="1 hour">1 hour</option>
            <option value="1.5 hours">1.5 hours</option>
            <option value="2 hours">2 hours</option>
            <option value="3 hours">3 hours</option>
        </select>
    </div>
);

export default DurationInput;