// src/utils/logger.js
const logger = {
  log: (...args) => {
    if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'local') {
      console.log(...args); // Spread args to log multiple arguments
    }
  },
  error: (...args) => {
    console.error(...args); // Always log errors using console.error
  },
  warn: (...args) => {
    if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'local') {
      console.warn(...args); // Use console.warn in development
    }
  },
  info: (...args) => {
    if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'local') {
      console.info(...args); // Use console.info in development
    }
  },
  debug: (...args) => {
    if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'local') {
      console.debug(...args); // Use console.debug in development
    }
  }
};

module.exports = logger;