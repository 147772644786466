import React from 'react';
import { FaUpload } from 'react-icons/fa';

const ReusableButton = ({ onClick, children, type, href }) => {
    if (href) {
        return (
            <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center justify-center border-2 border-purple-600 text-purple-600 bg-white font-bold py-2 px-4 rounded-md transition-colors duration-300 hover:bg-purple-600 hover:text-white"
            >
                {type === 'upload' && <FaUpload className="mr-2" />}
                {children}
            </a>
        );
    }

    return (
        <button
            type="button"
            onClick={onClick}
            className="inline-flex items-center justify-center border-2 border-purple-600 text-purple-600 bg-white font-bold py-2 px-4 rounded-md transition-colors duration-300 hover:bg-purple-600 hover:text-white"
        >
            {type === 'upload' && <FaUpload className="mr-2" />}
            {children}
        </button>
    );
};

export default ReusableButton;