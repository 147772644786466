import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import UserProfileDropDown from '../screens/user-profile/UserProfileDropDown';


const HamburgerMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isAdmin } = useAuth();
  const menuRef = useRef(null); // Define menuRef using useRef
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    // Check if the click is on the hamburger icon
    if (menuRef.current && (menuRef.current.contains(event.target) || event.target.closest('.hamburger-icon'))) {
        return;
    }
    
    // Close the menu if the click is outside
    setIsMenuOpen(false);
};

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const updateHeaderHeight = () => {
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight);
      }
    };
  
    updateHeaderHeight(); // Set the initial height
  
    window.addEventListener('resize', updateHeaderHeight); // Update height on resize
  
    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, []);

  return (
    <div className="Header border-b border-rppBorderGrey">
      <header 
        ref={headerRef} // Attach the ref to the header
        className="flex justify-between items-end p-3 bg-rppWhiteGrey text-rppPurple"
      >
      <div className="flex items-end flex-1">
      <div className="md:hidden cursor-pointer hamburger-icon" onClick={toggleMenu}>
          <div className="w-6 h-1 bg-rppPurple mb-1"></div>
          <div className="w-6 h-1 bg-rppPurple mb-1"></div>
          <div className="w-6 h-1 bg-rppPurple"></div>
        </div>
        <Link 
          to="/" 
          onClick={toggleMenu} 
          className="flex-1 text-rppPurple text-sm md:text-base font-bold custom-header pl-4 md:pl-4 text-center md:text-left"
          >
          Reading Philosophy and Psychology Circle (BETA)
        </Link>
        </div>        
        <nav
          ref={menuRef}
          className={`transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} fixed left-0 h-full bg-rppWhiteGrey text-white z-50 w-64 transition-transform duration-300 ease-in-out md:static md:transform-none md:w-auto md:flex md:flex-col`}
          style={{ top: `${headerHeight + 0.5}px` }}
        >
          <ul className="space-y-4 p-0 md:space-y-0 md:space-x-6 md:flex md:flex-row md:items-end h-full">
            <li className="ml-4 mt-4 md:ml-0 md:mt-0">
            </li>
            <li className="ml-4 md:ml-0">
            <Link to="/events" onClick={toggleMenu} className="text-base font-bold text-rppPurple md:text-rppPurple hover:underline">
                Events
            </Link>
            </li>
            <li className="ml-4 md:ml-0">
            <Link to="/communities" onClick={toggleMenu} className="text-base font-bold text-rppPurple md:text-rppPurple hover:underline">
                Communities
            </Link></li>
            {isAdmin && <li className="ml-4 md:ml-0">
            <Link to="/members" onClick={toggleMenu} className="text-base font-bold text-rppPurple md:text-rppPurple hover:underline">
                Members
            </Link>
            </li>}
            {isAdmin && <li className="ml-4 md:ml-0">
            <Link to="/dashboard" onClick={toggleMenu} className="text-base font-bold text-rppPurple md:text-rppPurple hover:underline">
                Dashboard
            </Link>
            </li>}
            <li className="ml-4 md:ml-0">
            <Link to="/about" onClick={toggleMenu} className="text-base font-bold text-rppPurple md:text-rppPurple hover:underline">
                About
            </Link>
            </li>
            <li className="ml-4 md:ml-0"><Link to="/faq" onClick={toggleMenu} className="text-base font-bold text-rppPurple md:text-rppPurple hover:underline">
                FAQ
            </Link>
            </li>
          </ul>
        </nav>
        <UserProfileDropDown />
      </header>
    </div>
  );
};

export default HamburgerMenu;