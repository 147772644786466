import React from 'react';
import CommunityForm from './CommunityForm';

const NewCommunity = () => {
    return (
        <div className="flex flex-col items-center mt-6 px-4 py-4">
            <h1 className="text-3xl font-bold text-purple-700 mb-4">New Community</h1>
            <div className="w-full max-w-6xl bg-white border-2 border-purple-600 rounded-lg p-6 shadow-lg">
                <CommunityForm />
            </div>
        </div>
    );
};

export default NewCommunity;