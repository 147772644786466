import React from 'react';
import Cropper from 'react-easy-crop';
import { FaTimesCircle } from 'react-icons/fa';

const ImagePreview = ({ localImageSrc, crop, zoom, aspect, onCropChange, onZoomChange, onCropComplete, onMediaLoaded, handleImageRemove }) => {
    return (
        <div className="relative w-full h-[500px] flex items-center justify-center">
            <Cropper
                image={localImageSrc}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                onCropChange={onCropChange}
                onZoomChange={onZoomChange}
                onCropComplete={onCropComplete}
                onMediaLoaded={onMediaLoaded}
                showGrid={false}
                cropShape="rect"
                restrictPosition={false}
                minZoom={0.1}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                zoomWithScroll={false}
            />
            <button
                type="button"
                onClick={handleImageRemove}
                className="absolute top-2 right-2 text-purple-600"
            >
                <FaTimesCircle size={24} />
            </button>
        </div>
    );
};

export default ImagePreview;