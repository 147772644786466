// src/components/ErrorPage.js

import React from 'react';

const ErrorPage = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-red-100">
            <div className="bg-white p-8 rounded-lg shadow-md">
                <h1 className="text-2xl font-bold text-red-700 mb-4">Authentication Failed</h1>
                <p>Please try again.</p>
            </div>
        </div>
    );
};

export default ErrorPage;