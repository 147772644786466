import { useState, useEffect, useCallback } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import logger from '../utils/logger';

const useFetchMember = (memberId) => {
  const [member, setMember] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchMember = useCallback(async () => {
    if (!memberId) {
      return;
    }

    setLoading(true);
    setError(null);
    try {
      logger.log(`useFetchMember hook, useFetchMember: Fetching member with ID: ${memberId}`);
      const memberRef = doc(db, 'members', memberId);
      const memberSnapshot = await getDoc(memberRef);

      if (memberSnapshot.exists()) {
        const memberData = memberSnapshot.data();
        const resolvedMemberId = memberData.memberId || memberId; // Use field or fallback to document ID
        logger.log('useFetchMember hook, useFetchMember: Member found:', memberData);

        setMember({ ...memberData, memberId: resolvedMemberId });
      } else {
        logger.error('Member not found for ID:', memberId);
        setError('Member not found');
      }
    } catch (err) {
      logger.error('Error fetching member details:', err);
      setError('Error fetching member details');
    } finally {
      setLoading(false);
    }
  }, [memberId]);

  useEffect(() => {
    fetchMember();
  }, [fetchMember]);

  return { member, loading, error, refetch: fetchMember };
};

export default useFetchMember;