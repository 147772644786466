import React from 'react';

const DateTimeInput = ({ register }) => (
    <div>
        <label className="block text-lg font-bold mb-2">Date & Time</label>
        <input
            type="datetime-local"
            {...register('dateTime', { required: true })}
            className="w-full p-2 border border-gray-300 rounded-md"
        />
    </div>
);

export default DateTimeInput;
