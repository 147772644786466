import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { getAuth } from 'firebase/auth';
import logger from '../utils/logger';

const useFetchMembers = (communityId, refreshKey) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdminOrMember, setIsAdminOrMember] = useState(false);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        let isAdmin = false;
        let isMember = false;

        if (user) {
          logger.log("User is logged in:", user.uid);
          const idTokenResult = await user.getIdTokenResult();
          isAdmin = !!idTokenResult.claims.admin;
          logger.log("User is admin:", isAdmin);

          // Check if the user is a member of the community
          const memberDoc = await getDoc(doc(db, 'community_members', `${communityId}_${user.uid}`));
          isMember = memberDoc.exists();
          logger.log("User is member of community:", isMember);
        }

        setIsAdminOrMember(isAdmin || isMember);

        // Only proceed with fetching members if user is admin or member
        if (isAdmin || isMember) {
          // Query community_members collection
          const q = query(
            collection(db, 'community_members'),
            where('communityId', '==', communityId)
          );

          const querySnapshot = await getDocs(q);
          logger.log(`Fetched ${querySnapshot.docs.length} documents from community_members`);
          const membersData = [];

          for (const docSnap of querySnapshot.docs) {
            const memberData = docSnap.data();
            logger.log(`Processing community_members document:`, memberData);
            const memberRef = doc(db, 'members', memberData.memberId);
            const memberSnapshot = await getDoc(memberRef);

            if (memberSnapshot.exists()) {
              const member = memberSnapshot.data();
              logger.log(`Fetched member data for memberId ${memberData.memberId}:`, member);

              membersData.push({
                ...memberData,
                displayName: member.displayName,
                profilePictureUrl: member.profilePictureUrl,
                role: memberData.role,
              });

              logger.log(`Combined data for memberId ${memberData.memberId}:`, {
                ...memberData,
                displayName: member.displayName,
                profilePictureUrl: member.profilePictureUrl,
                role: memberData.role,
              });
            }
          }

          logger.log("Members data fetched:", membersData);  // Log the fetched members data
          setMembers(membersData);
        } else {
          logger.log("User is neither an admin nor a member. Not fetching members.");
          setError(null); // Clear any previous error messages
        }
      } catch (err) {
        if (err.code === 'permission-denied') {
          logger.log('User does not have permission to fetch members.');
          setError(null); // Do not set an error message
        } else {
          logger.error('Error fetching members:', err);
          setError('Error fetching members');
        }
      } finally {
        setLoading(false);
      }
    };

    if (communityId) {
      fetchMembers();
    }
  }, [communityId, refreshKey]);

  return { members, loading, error, isAdminOrMember };
};

export default useFetchMembers;