import React from 'react';
import { FaSearchPlus, FaSearchMinus, FaRedo } from 'react-icons/fa';

const ZoomControls = ({ zoom, onZoomChange, onReset }) => {
  return (
    <div className="flex space-x-2">
      <button
        type="button"
        onClick={() => onZoomChange(Math.max(zoom - 0.2, 0.1))}
        className="text-purple-600"
      >
        <FaSearchMinus size={24} />
      </button>
      <button
        type="button"
        onClick={() => onZoomChange(Math.min(zoom + 0.2, 3))}
        className="text-purple-600"
      >
        <FaSearchPlus size={24} />
      </button>
      <button
        type="button"
        onClick={onReset}
        className="text-purple-600"
      >
        <FaRedo size={24} />
      </button>
    </div>
  );
};

export default ZoomControls;