import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image', 'video'],
    ['clean'],
  ],
};

const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

const DescriptionEditor = ({ value, onChange }) => (
  <div className="mb-8 p-4 bg-white rounded-md shadow-md h-96">
    <label className="block text-gray-700 font-bold mb-2">Description</label>
    <ReactQuill
      value={value}
      onChange={onChange}
      modules={modules}
      formats={formats}
      className="bg-white h-full" // Ensures the editor takes the full height of the container
      style={{ height: '75%' }} // Adds height to the editor's internal content area
    />
  </div>
);

export default DescriptionEditor;