import React, { useState, useEffect } from 'react';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useFetchMember from '../../../hooks/useFetchMember';
import UserProfileDetails from './UserProfileDetails';
import ConnectWithMeetup from './ConnectWithMeetup';
import useIsAdmin from '../../../hooks/useIsAdmin';
import logger from '../../../utils/logger';
import LoadingSpinner from '../../common/LoadingSpinner';

/**
 * UserProfileScreen component to manage and display the user's profile.
 *
 * @returns {JSX.Element} Rendered component
 */
const UserProfileScreen = () => {
  const currentUser = useCurrentUser();
  const { member: fetchedMember, 
    loading, 
    error, 
    refetch 
  } = useFetchMember(currentUser.uid);

  const isAdmin = useIsAdmin(); // Use the useIsAdmin hook

  // Define state for member and setMember
  const [member, setMember] = useState(fetchedMember)

  useEffect(() => {
    // Update member state when fetchedMember changes
    setMember(fetchedMember);
  }, [fetchedMember]);

  // Placeholder function for onFieldChange
  const handleFieldChange = (field, value) => {
    logger.log(`UserProfileScreen, handleFieldChange (callback) executed: Field changed: ${field}, New value: ${value}`);
    setMember(prevState => ({ ...prevState, [field]: value }));
  };

  if (loading) return <LoadingSpinner></LoadingSpinner>;

  return (
    <div className="flex flex-col items-center mt-20">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      <UserProfileDetails
        displayName={member?.displayName || 'No display name'}
        userEmail={currentUser.email}
        profilePictureUrl={member?.profilePictureUrl || ''}
        onFieldChange={handleFieldChange}
      />
      {isAdmin && <ConnectWithMeetup />}
      {/* 
      <UserProfileModal
        showSuccessModal={showSuccessModal}
        handleSuccessModalClose={() => setShowSuccessModal(false)}
        showErrorModal={showErrorModal}
        errorMessage={errorMessage}
        showConfirmModal={showConfirmModal}
        handleDeleteAccount={deleteUserAccount}
        handleCloseConfirmModal={() => setShowConfirmModal(false)}
      />
      <Modal isOpen={editingImage} onClose={() => setEditingImage(false)}>
        <ImageUpload
          label="Upload Profile Picture"
          imageSrc={imageSrc}
          onImageChange={setImageSrc} // This updates the imageSrc when the image changes
          onSave={() => handleCropComplete(imageSrc, 'profile')} // This saves the image when the user clicks save
          cropShape="round"
          aspect={1}
          recommendedResolution='600 x 600'
        />
      </Modal>
      <PasswordConfirmModal
        isOpen={showPasswordConfirmModal}
        onClose={closePasswordConfirmModal}
        onConfirm={handleDeleteAccount}
        provider={authProvider}
      /> 
      */}
    </div>
  );
};

export default UserProfileScreen;
