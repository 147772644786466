// src/hooks/useFetchEvent.js

import { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import logger from '../utils/logger';

const useFetchEvent = (eventId) => {
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const docRef = doc(db, 'events', eventId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setEventData({ id: docSnap.id, ...docSnap.data() });
        } else {
          setError('No such event!');
        }
      } catch (err) {
        logger.error('Error fetching event:', err);
        setError('Error fetching event');
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [eventId]);

  return { eventData, loading, error };
};

export default useFetchEvent;