import React from 'react';
import { useParams } from 'react-router-dom';
import EventForm from './EventForm';
import useFetchEvent from '../../../hooks/useFetchEvent';
import { getAuth } from 'firebase/auth';
import { useAuth } from '../../../contexts/AuthContext';

const EditEvent = () => {
    const { id } = useParams();
    const { eventData, loading, error } = useFetchEvent(id);
    const { currentUser } = useAuth();
    const auth = getAuth();
    const ownerInfo = auth.currentUser ? {
        uid: auth.currentUser.uid,
        displayName: auth.currentUser.displayName,
        photoURL: auth.currentUser.photoURL,
        email: auth.currentUser.email,
    } : null;

    const hostInfo = currentUser; // Assuming hostInfo is fetched similarly

    if (loading) return <p className="text-xl text-purple-600">Loading...</p>;
    if (error) return <p className="text-xl text-red-600">{error}</p>;

    return (
        <div className="flex flex-col items-center mt-6 px-4 py-4">
            <h1 className="text-3xl font-bold text-purple-700 mb-4">Edit Event</h1>
            <div className="w-full max-w-6xl bg-white border-2 border-purple-600 rounded-lg p-6 shadow-lg">
                {eventData && (
                    <EventForm
                        eventData={eventData}
                        isEditing={true}
                        hostInfo={hostInfo}
                        ownerInfo={ownerInfo}
                    />
                )}
            </div>
        </div>
    );
};

export default EditEvent;