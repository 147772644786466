import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useFetchInvitation from '../../../hooks/useFetchInvitation';
import Modal from '../../common/modals/Modal';
import LoginComponent from '../../common/auth-components/LoginComponent';
import SignUpComponent from '../../common/auth-components/SignUpComponent';
import useCurrentUserId from '../../../hooks/useCurrentUserId';
import useAcceptCommunityInvitation from '../../../hooks/useAcceptCommunityInvitation';

const CommunityAcceptInvitation = () => {
  const { inviteId } = useParams();
  const { invitationData, loading, error } = useFetchInvitation(inviteId);
  const [actionCompleted, setActionCompleted] = useState(false);
  const [actionError, setActionError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userId = useCurrentUserId(); // Fetch the current user's ID
  const { acceptInvitation, loading: accepting, error: acceptError } = useAcceptCommunityInvitation();
  const navigate = useNavigate();

  const handleAccept = async () => {
    if (!userId) {
      setIsModalOpen(true);
      return;
    }

    try {
      const result = await acceptInvitation(invitationData.communityId, inviteId);
      if (result.success) {
        setActionCompleted(true);
        navigate(`/community/${invitationData.communityId}`);
      } else {
        setActionError('Failed to accept the invitation.');
      }
    } catch (err) {
      console.error('Error accepting invitation:', err);
      setActionError('Failed to accept the invitation.');
    }
  };

  const handleDecline = async () => {
    try {
      // Logic to decline the invitation (e.g., deleting the invitation document)
      await acceptInvitation(invitationData.communityId, inviteId);
      setActionCompleted(true);
    } catch (err) {
      console.error('Error declining invitation:', err);
      setActionError('Failed to decline the invitation.');
    }
  };

  const handleModalClose = () => setIsModalOpen(false);

  const renderModalContent = () => {
    return <LoginComponent onClose={handleModalClose} onLoginSuccess={handleAccept} />;
  };

  if (loading || accepting) return <p className="text-xl text-purple-600 text-center">Loading...</p>;
  if (error || actionError || acceptError) return <p className="text-xl text-red-600 text-center">{error || actionError || acceptError}</p>;
  if (actionCompleted) return <p className="text-xl text-green-600 text-center">Action completed successfully.</p>;

  return (
    <div className="flex flex-col items-center py-4 px-4 mt-8 w-full">
      <div className="w-full max-w-4xl bg-white border-2 border-purple-600 rounded-lg p-6 shadow-lg mb-4">
        <h1 className="text-3xl font-bold text-purple-800 mb-4 text-center">
          {invitationData.invitedBy || 'Someone'} has invited you to join {invitationData.communityName || 'this community'}
        </h1>
        <div className="flex justify-around mt-4">
          <button
            onClick={handleAccept}
            className="bg-green-500 text-white px-6 py-2 rounded-md hover:bg-green-600"
          >
            Accept Invitation
          </button>
          <button
            onClick={handleDecline}
            className="bg-red-500 text-white px-6 py-2 rounded-md hover:bg-red-600"
          >
            Decline Invitation
          </button>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={handleModalClose}>
        {renderModalContent()}
      </Modal>
    </div>
  );
};

export default CommunityAcceptInvitation;