import { useState, useEffect } from 'react';
import { db } from '../firebaseConfig'; // Adjust path as needed
import { doc, getDoc } from 'firebase/firestore';
import logger from '../utils/logger';

const useFetchCommunity = (communityId) => {
  const [community, setCommunity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCommunity = async () => {
      if (!communityId) {
        setError('Invalid community ID');
        setLoading(false);
        return;
      }

      try {
        logger.log('Fetching community with ID:', communityId);
        logger.log('Firestore DB:', db);

        const docRef = doc(db, 'communities', communityId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setCommunity({ id: docSnap.id, ...docSnap.data() });
        } else {
          setError('Community not found');
        }
      } catch (err) {
        logger.error('Error fetching community:', err.message, err.stack);
        setError('Failed to fetch community data');
      } finally {
        setLoading(false);
      }
    };

    fetchCommunity();
  }, [communityId]);

  return { community, loading, error };
};

export default useFetchCommunity;