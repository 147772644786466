// src/hooks/useCurrentUser.js

import { useAuth } from '../contexts/AuthContext';
import logger from '../utils/logger';

const useCurrentUser = () => {
  const { currentUser } = useAuth();
  return currentUser || null; // Ensure the function returns null if currentUser is undefined
};

export default useCurrentUser;