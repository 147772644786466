import React from 'react';
import { Link } from 'react-router-dom';
import ReusableButton from '../../common/reusable-button/ReusableButton';

const EventItem = ({ event, formatDate, parseDuration }) => {
    return (
        <div className="w-full max-w-4xl my-4">
            <div className="bg-white border-2 border-purple-600 rounded-lg p-6 shadow-lg flex flex-col lg:flex-row items-center transition-transform duration-300 hover:scale-102 hover:shadow-xl">
                
                {/* Link wrapping the image */}
                {event.meetupImage && (
                    <Link to={`/event/${event.id}`} className="w-full lg:w-1/3 h-auto mb-4 lg:mb-0 lg:mr-4 no-underline">
                        <img
                            src={event.meetupImage}
                            alt={event.title}
                            className="w-full h-auto rounded-md object-cover"
                        />
                    </Link>
                )}
                
                <div className="flex-1">
                    {/* Link wrapping only the title */}
                    <Link to={`/event/${event.id}`} className="no-underline">
                        <h2 className="text-2xl font-bold text-purple-800 mb-2">{event.title}</h2>
                    </Link>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-2">
                        <div className="text-gray-700 font-normal">
                            <strong className="font-bold">Date & Time:</strong> {formatDate(event.dateTime)}
                        </div>
                        <div className="text-gray-700 font-normal">
                            <strong className="font-bold">Host:</strong> {event.hostName}
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-2">
                        <div className="text-gray-700 font-normal">
                            <strong className="font-bold">Duration:</strong> {event.duration ? parseDuration(event.duration) : 'Unknown duration'}
                        </div>
                        <div className="text-gray-700 font-normal">
                            <strong className="font-bold">Group: </strong>
                            <Link to={`/community/${event.groupId}`} className="text-black hover:underline ml-1">
                                {event.groupName}
                            </Link>
                        </div>
                    </div>
                    <div className="text-right">
                        {/* Link wrapping only the button */}
                        <Link to={`/event/${event.id}`} className="no-underline">
                            <ReusableButton>
                                View Event
                            </ReusableButton>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventItem;