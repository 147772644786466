import React, { useEffect, useState } from 'react';
import { FaUserCircle, FaCheckCircle, FaExclamationCircle, FaEdit } from 'react-icons/fa';
import UserProfileDetailsButtons from './UserProfileDetailsButtons';
import Modal from '../../common/modals/Modal'
import ImageUpload from '../../common/image-editor/ImageUpload';
import logger from '../../../utils/logger';
import useUpdateProfilePicture from '../../../hooks/useUpdateProfilePicture';
import useCurrentUser from '../../../hooks/useCurrentUser'; // Import the custom hook



/**
 * UserProfileDetails component to display and edit user profile details.
 *
 * @param {Object} props - Component props
 * @param {boolean} props.isEditing - Flag to indicate if the profile is in edit mode
 * @param {string} props.updatedDisplayName - Updated display name state
 * @param {Function} props.setUpdatedDisplayName - Function to set the updated display name
 * @param {Function} props.onVerifyEmail - Function to verify user's email
 * @param {Function} props.onEditImage - Function to edit user's profile image
 * @param {Function} props.onDeleteAccount - Function to delete user's account
 * @param {Function} props.onResetPassword - Function to reset user's password
 * @param {Function} props.onEditProfile - Function to edit user's profile
 * @param {Function} props.onSaveChanges - Function to save profile changes
 * @param {Function} props.onCancelEdit - Function to cancel profile edit
 * @param {string} props.imageSrc - Current profile image URL
 * @returns {JSX.Element} Rendered component
 */
const UserProfileDetails = ({
  displayName,
  userEmail,
  profilePictureUrl,
  onFieldChange
}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [localprofilePictureUrl, setLocalprofilePictureUrl] = useState(profilePictureUrl);
  const currentUser = useCurrentUser(); // Use the custom hook to get the current user
  const { updateProfilePicture, updating, error } = useUpdateProfilePicture(); // Destructure the hook

  useEffect(() => {
    setLocalprofilePictureUrl(profilePictureUrl);
    logger.log('UserProfileDetails, useEffect: Updated profilePictureUrl:', profilePictureUrl);  // Log the updated URL
  }, [profilePictureUrl]);

  const handleImageSave = async (newImageDownloadUrl) => {
    logger.log('UserProfileDetails, handleImageSave: New Image URL:', newImageDownloadUrl);  // Log the new URL
    setIsModalOpen(false);
    setLocalprofilePictureUrl(newImageDownloadUrl);  // Update local state
    
    // Update the Firestore and user profile using the hook
    if (currentUser) {
      await updateProfilePicture(newImageDownloadUrl, currentUser);
    }

    onFieldChange('profilePictureUrl', newImageDownloadUrl);
  };

  return (
    <div className="flex flex-col items-center py-4 px-4 mt-4">
        <div className="w-full max-w-4xl bg-white shadow-md rounded-lg p-6 mb-8">
            <div className="relative w-24 h-24 mx-auto mb-6">
                {profilePictureUrl ? (
                    <img
                        src={localprofilePictureUrl}
                        alt={displayName}
                        className="w-full h-full rounded-full object-cover border-2 border-gray-400"
                    />
                ) : (
                    <FaUserCircle className="w-full h-full rounded-full text-gray-400" />
                )}
                <FaEdit
                    className="absolute bottom-2 right-2 text-purple-600 hover:text-purple-800 cursor-pointer"
                    onClick={() => setIsModalOpen(true)}
                />
            </div>
            <div className="text-lg text-gray-700 mb-4">
                <strong>Email:</strong> {userEmail || 'No email provided'}
            </div>  
            <div className="text-lg text-gray-700 mb-4">
                <strong>Display Name: </strong>
                <span>{displayName || 'No display name'}</span>
            </div>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <ImageUpload
                    label="Upload Profile Picture"
                    imageSrc={localprofilePictureUrl}
                    fileType='profile'
                    onImageSave={handleImageSave}
                    cropShape="round"
                    aspect={1}
                    recommendedResolution="600 x 600"
                />
            </Modal>
        </div>
    </div>
);
};

export default UserProfileDetails;


        {/* <UserProfileDetailsButtons
          onDeleteAccount={onDeleteAccount}
          onResetPassword={onResetPassword}
          onEditProfile={onEditProfile}
          isEditing={isEditing}
          onSaveChanges={onSaveChanges}
          onCancelEdit={onCancelEdit}
        /> */}