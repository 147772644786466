import React, { useState, useEffect } from 'react';
import { db } from '../../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../../contexts/AuthContext';
import EventForm from './EventForm';
import { getAuth } from 'firebase/auth';
import logger from '../../../utils/logger';

const NewEvent = () => {
    const { currentUser } = useAuth();
    const [hostInfo, setHostInfo] = useState(null);
    const [ownerInfo, setOwnerInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const auth = getAuth();

    useEffect(() => {
        const fetchHostInfo = async () => {
            if (currentUser) {
                try {
                    const connectionDoc = await getDoc(doc(db, 'user_member_connection', currentUser.uid));
                    if (connectionDoc.exists()) {
                        const memberId = connectionDoc.data().memberId;
                        const memberDoc = await getDoc(doc(db, 'members', memberId));
                        if (memberDoc.exists()) {
                            setHostInfo(memberDoc.data());
                        }
                    }
                } catch (error) {
                    logger.error('Error fetching host info: ', error);
                    setError('Error fetching host info');
                }
            }
        };

        const fetchOwnerInfo = async () => {
            if (auth.currentUser) {
                const { uid, displayName, photoURL, email } = auth.currentUser;
                setOwnerInfo({ uid, displayName, photoURL, email });
            }
            setLoading(false);
        };

        fetchHostInfo();
        fetchOwnerInfo();
    }, [currentUser, auth]);

    return (
        <div className="flex flex-col items-center mt-6 px-4 py-4">
            <h1 className="text-3xl font-bold text-purple-700 mb-4">New Event</h1>
            <div className="w-full max-w-6xl bg-white border-2 border-purple-600 rounded-lg p-6 shadow-lg">
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <EventForm hostInfo={hostInfo} ownerInfo={ownerInfo} />
                )}
                {error && <p className="text-red-600">{error}</p>}
            </div>
        </div>
    );
};

export default NewEvent;