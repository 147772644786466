import React, { useState, useEffect, useRef } from 'react';
import { useForm, ErrorMessage } from 'react-hook-form';
import ImageUpload from '../../common/image-editor/ImageUpload';
import DescriptionEditor from '../../common/form-components/DescriptionEditor';
import StatusSelect from '../../common/form-components/StatusSelect';
import OwnerInfo from '../../common/form-components/OwnerInfo';
import TextInput from '../../common/form-components/TextInput';
import CommunityMembersManager from '../../common/form-components/CommunityMembersManager';
import { useNavigate } from 'react-router-dom';
import logger from '../../../utils/logger';
import useAddCommunity from '../../../hooks/useAddCommunity';
import useUpdateCommunity from '../../../hooks/useUpdateCommunity';
import useFetchCurrentUserMember from '../../../hooks/useFetchCurrentUserMember';


const CommunityForm = ({ communityData, isEditing = false }) => {
    const { register, handleSubmit, setValue, formState: { errors }, setFocus } = useForm();
    const [description, setDescription] = useState('');
    const [imageSrc, setImageSrc] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { addCommunity } = useAddCommunity();
    const { updateCommunity } = useUpdateCommunity();
    const imageUploadRef = useRef(null); // Create a ref for ImageUpload
    const { member, loading: memberLoading, error: memberError } = useFetchCurrentUserMember(); // Fetch current user's member details

    // Combine loading states into a generic loading variable
    const isLoading = loading || memberLoading;

    useEffect(() => {
      if (member) {
        logger.log('Member object:', member); // Log the member object
        const { uid, displayName, profilePictureUrl, email } = member;
      } else if (memberError) {
        logger.error('Error fetching member details:', memberError);
      }
    }, [member, memberError]);

    useEffect(() => {
        if (isEditing && communityData) {
            setValue('name', communityData.name);
            setValue('status', communityData.status);
            setDescription(communityData.description || '');
            setImageSrc(communityData.imageUrl || '');
        }
    }, [isEditing, communityData, setValue]);

    const onSubmit = async (data) => {
      if (Object.keys(errors).length > 0) {
          const firstErrorKey = Object.keys(errors)[0];
          setFocus(firstErrorKey);
          return;
        }
        logger.log('onSubmit called with data:', data);
        if (!member) {
          logger.error('Cannot submit form without member information.');
          setError('member information is missing.');
          return;
        }
      
        setLoading(true);
        try {
            logger.log('Starting community save process...');
            let imageUrl = communityData?.imageUrl || '';

            if (imageUploadRef.current) {
              // Trigger the save method in ImageUpload
              const uploadedImageUrl = await imageUploadRef.current.handleSave();
              if (uploadedImageUrl) {
                imageUrl = uploadedImageUrl;
              }
            }
          
            // Ensure description is not undefined
            const updatedDescription = description || '';
            let communityId;

            const updatedData = { 
              ...data,
              description: updatedDescription, 
              imageUrl, 
              ownerId: communityData?.ownerId || member.uid // Keep the existing ownerId or use current user
            };
            
            if (isEditing) {
              logger.log('Updating existing community with ID:', communityData.id);
              await updateCommunity(communityData.id, updatedData);
              communityId = communityData.id;
            } else {
              logger.log('Creating new community...');
              if (!member) {
                logger.error('Member information is missing or incomplete:', member);
                setError('Member information is missing or incomplete.');
                return;
              } else {
                logger.log('Member UID:', member.uid);
              }
              communityId = await addCommunity(updatedData, member.memberId);
            }
          
            if (communityId) {
              logger.log('Community saved with ID:', communityId);

              // Save the image with the correct communityId
              if (imageUploadRef.current) {
              const uploadedImageUrl = await imageUploadRef.current.handleSave();
              if (uploadedImageUrl) {
              imageUrl = uploadedImageUrl;
              // Optionally update the community with the correct imageUrl
                await updateCommunity(communityId, { ...data, imageUrl });
              }
            }

              navigate(`/community/${communityId}`);
            } else {
              logger.error('Failed to retrieve community ID after saving.');
              setError('Failed to save community. Please try again.');
            }
          } catch (error) {
            logger.error('Error saving community:', error.message, error.stack);
            setError('Error saving community. Please try again.');
          } finally {
            logger.log('Community save process complete.');
            setLoading(false);
          }
        }
      

    const handleEditorChange = (content) => {
        setDescription(content);
    };

    const handleImageChange = (imageDataUrl) => {
        setImageSrc(imageDataUrl);
    };

    return (
      <>
          {isLoading ? (
              <p>Loading...</p> // Display a loading indicator when data is still being fetched
          ) : (
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="space-y-4">
                  <div>
                      <TextInput
                          label="Name"
                          name="name"
                          register={register}
                          required={true}
                      />
                      {/* Display error message */}
                      {errors.name && (
                          <p className="text-red-600">Community requires a name.</p>
                      )}
                  </div>
                  <OwnerInfo ownerInfo={member} />
                  {/* 
                    <CommunityMembersManager communityData={communityData} /> 
                  */}
                  <ImageUpload
                      label="Upload Community Picture"
                      imageSrc={imageSrc}
                      fileType='community'
                      imageId={isEditing ? communityData.id : null}
                      allowSave={false}
                      ref={imageUploadRef}
                  />
                  <StatusSelect
                      label="Community Type"
                      tooltipText="Draft: Not live and only saved for the user. Hidden: Only invited people can see it. Private: Listed in the app, but detailed info requires joining. Public: Everyone can find, see, and join."
                      register={register}
                      options={[
                          { value: 'DRAFT', label: 'Draft' },
                          { value: 'HIDDEN', label: 'Hidden' },
                          { value: 'PRIVATE', label: 'Private' },
                          { value: 'PUBLIC', label: 'Public' },
                      ]}
                  />
                  <div>
                      <DescriptionEditor value={description} onChange={handleEditorChange} />
                  </div>
                  {error && <p className="text-red-600">{error}</p>}
                  <div className="text-right mt-4">
                      <button
                          type="submit"
                          className="bg-purple-600 text-white font-bold py-2 px-4 rounded-md shadow-md hover:bg-purple-700 transition-colors duration-300"
                          disabled={isLoading} // Disable form submission while loading
                      >
                          {isLoading ? 'Saving...' : isEditing ? 'Update Community' : 'Create Community'}
                      </button>
                  </div>
              </form>
          )}
      </>
  );
};

export default CommunityForm;
