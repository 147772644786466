import React, { useState, useEffect, useRef } from 'react';
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';
import { useAuth } from '../../../contexts/AuthContext';
import { FaUserCircle, FaThumbsUp, FaComment, FaEdit, FaTrash } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import LoadingSpinner from '../../common/LoadingSpinner';
import ReusableButton from '../../common/reusable-button/ReusableButton';
import { useNavigate } from 'react-router-dom';
import logger from '../../../utils/logger';

const CommunitySuggestions = ({ communityId }) => {
  const { currentUser } = useAuth();
  const [suggestions, setSuggestions] = useState([]);
  const [newSuggestion, setNewSuggestion] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const suggestionBoxRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const q = query(collection(db, 'suggestions'), where('communityId', '==', communityId));
        const querySnapshot = await getDocs(q);
        const suggestionsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt.toDate(),
        }));

        setSuggestions(suggestionsData);
      } catch (err) {
        logger.error('Error fetching suggestions:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSuggestions();
  }, [communityId]);

  useEffect(() => {
    // Close the suggestion box if clicked outside
    const handleClickOutside = (event) => {
      if (suggestionBoxRef.current && !suggestionBoxRef.current.contains(event.target)) {
        setIsExpanded(false); // Collapse the suggestion box
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [suggestionBoxRef]);

  const handleAddSuggestion = async () => {
    if (!currentUser) return alert('You must be logged in to add a suggestion.');

    const newDoc = await addDoc(collection(db, 'suggestions'), {
      text: newSuggestion,
      votes: 0,
      upvotedBy: [],
      comments: [],
      userId: currentUser.uid,
      username: currentUser.displayName || 'Anonymous',
      userProfilePic: currentUser.photoURL || '',
      createdAt: new Date(),
      communityId,
    });

    const newSuggestions = [
      ...suggestions, 
      { 
        id: newDoc.id, 
        text: newSuggestion, 
        votes: 0, 
        upvotedBy: [], 
        comments: [], 
        userId: currentUser.uid, 
        username: currentUser.displayName || 'Anonymous', 
        userProfilePic: currentUser.photoURL || '', 
        createdAt: new Date(), 
        communityId 
      }
    ];

    setSuggestions(newSuggestions.sort((a, b) => b.votes - a.votes));
    setNewSuggestion('');
    setIsExpanded(false);
  };

  const handleSuggestionClick = (suggestionId) => {
    navigate(`/suggestions/${suggestionId}`);
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <p className="text-xl text-red-600">Error: {error}</p>;

  return (
    <div className="w-full max-w-4xl mx-auto">
      <div ref={suggestionBoxRef} className="mb-6">
        <h2 className="text-xl font-bold text-purple-800 mb-4">Add a New Suggestion</h2>
        {isExpanded ? (
          <>
            <div className="mb-4">
              <ReactQuill
                value={newSuggestion}
                onChange={setNewSuggestion}
                className="w-full h-32"
                style={{ fontSize: '16px' }}
              />
            </div>
            <div className="flex justify-end">
              <ReusableButton onClick={handleAddSuggestion}>
                Add Suggestion
              </ReusableButton>
            </div>
          </>
        ) : (
          <div
            className="cursor-pointer p-2 border rounded-md text-gray-500 hover:text-gray-700"
            onClick={() => setIsExpanded(true)}
          >
            Click to add a new suggestion...
          </div>
        )}
      </div>

      <h2 className="text-xl font-bold text-purple-800 mb-4">Current Suggestions</h2>
      {suggestions.length === 0 ? (
        <p>No suggestions found for this community.</p>
      ) : (
        <ul>
          {suggestions.map(suggestion => (
            <li key={suggestion.id} className="mb-4 border-b border-gray-200 pb-4">
              <div 
                onClick={() => handleSuggestionClick(suggestion.id)} 
                className="cursor-pointer flex justify-between items-center"
              >
                <div>
                  <div className="font-semibold" dangerouslySetInnerHTML={{ __html: suggestion.text }} />
                  <div className="flex items-center text-sm text-gray-500">
                    {suggestion.userProfilePic ? (
                      <img src={suggestion.userProfilePic} alt="Profile" className="w-6 h-6 rounded-full mr-2" />
                    ) : (
                      <FaUserCircle className="w-6 h-6 mr-2" />
                    )}
                    Suggested by {suggestion.username} on {new Date(suggestion.createdAt).toLocaleString()}
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <button onClick={(e) => { e.stopPropagation(); /* handleUpvote logic */ }} className="flex items-center px-4 py-2 rounded-md transition duration-200 bg-purple-600 text-white hover:bg-purple-700">
                    <FaThumbsUp className="mr-2" /> {suggestion.votes}
                  </button>
                  <div className="flex items-center text-gray-600">
                    <FaComment className="mr-2" /> {suggestion.comments.length}
                  </div>
                  {/* Add edit/delete buttons if the user is the suggestion author */}
                  {suggestion.userId === currentUser?.uid && (
                    <div className="flex items-center space-x-2">
                      <FaEdit className="text-blue-500 cursor-pointer" /* handle edit logic */ />
                      <FaTrash className="text-red-500 cursor-pointer" /* handle delete logic */ />
                    </div>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CommunitySuggestions;