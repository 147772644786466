// File: src/utils/communityActions.js
import { db } from '../firebaseConfig';
import { setDoc, doc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import logger from './logger';

export async function joinCommunity(communityId, userId) {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    logger.error("User is not authenticated");
    return;
  }

  try {
    // Fixed role as "member"
    const role = 'member';

    // Define the data to be written
    const data = {
      memberId: userId,
      communityId: communityId,
      role: role // Set role as "member"
    };
    logger.log("Data to be written:", data);

    // Attempt to write the data
    await setDoc(doc(db, 'community_members', `${communityId}_${userId}`), data);
    logger.log(`Successfully joined community ${communityId} as a ${role}`);
  } catch (error) {
    logger.error('Error in joinCommunity function:', error);
    throw error; // Re-throw the error to handle it in the calling function
  }
}