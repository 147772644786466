import logger from './logger';

/**
 * Function to get the cropped image from the canvas.
 *
 * @param {string} imageSrc - The source URL or data URL of the image to be cropped.
 * @param {Object} pixelCrop - An object containing the dimensions and position of the cropped area in pixels.
 *                             It specifies the exact area (width, height, x, y) to be extracted from the image.
 * @returns {Promise<Blob>} - A promise that resolves with the cropped image as a Blob object.
 *                            The Blob can be used to display or upload the cropped image.
 */
const getCroppedImg = (imageSrc, pixelCrop) => {
  return new Promise((resolve, reject) => {
    // Load the image from the provided source
    const originalImage = new Image();
    // set cors so we can access remote URLs
    originalImage.crossOrigin = 'anonymous';
    originalImage.src = imageSrc;

    // event listener to wait for the image to load
    originalImage.onload = () => {
      // The image to manipulate
      let image = originalImage;
      
      logger.log('getCroppedImg: Original image resolution:', { width: originalImage.width, height: originalImage.height });
      logger.log('getCroppedImg: Pixelcrop image resolution:', {  width: pixelCrop.width, 
                                                                  height: pixelCrop.height,
                                                                  x: pixelCrop.x,
                                                                  y: pixelCrop.y
                                                               });


      // Check if the image needs to be enlarged
      const needsEnlargement = (

        // Scenario 1: The crop area's starting position (x or y) is negative, meaning it starts outside the image.
        pixelCrop.x < 0 || 
        pixelCrop.y < 0 ||

        // Scenario 2: The crop area extends beyond the right or bottom edge of the image.
        (pixelCrop.x + pixelCrop.width) > originalImage.width ||
        (pixelCrop.y + pixelCrop.height) > originalImage.height
      );
      
      // If any of the above conditions are true, the image needs to be enlarged
      if (needsEnlargement) {
        logger.log('getCroppedImg: Yes, image needs to be enlarged');
        const result = enlargeImage(originalImage, pixelCrop);
        image = result.canvas;
        pixelCrop = result.newPixelCrop;  // Update pixelCrop with new values
      } else {
        logger.log('getCroppedImg: No, image needs not to be enlarged');
      }

      const canvas = document.createElement('canvas');
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Canvas is empty'));
        return;
      }

      // Draw the cropped image on the canvas
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        canvas.width,
        canvas.height
      );

      logger.log('getCroppedImg: Image drawn on canvas');

      // Convert the canvas content to a Blob
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('getCroppedImg: Canvas is empty'));
          return;
        }
        blob.name = 'croppedImage.png';
        resolve(blob);
      }, 'image/png');
    };

    originalImage.onerror = (error) => reject(error);
  });
};

/**
 * Function to enlarge the image by adding extra whitespace if necessary.
 *
 * @param {HTMLImageElement} image - The original image element
 * @param {Object} pixelCrop - Cropped area dimensions
 * @returns {Object} - Object containing the enlarged image on a new canvas and the updated pixelCrop values
 */
const enlargeImage = (image, pixelCrop) => {
  logger.log('enlargeImage: Original image dimensions:', { width: image.width, height: image.height });
  logger.log('enlargeImage: pixelCrop dimensions:', { width: pixelCrop.width, height: pixelCrop.height });

  // Calculate new dimensions
  const newWidth = Math.max(image.width, pixelCrop.width + Math.abs(pixelCrop.x));
  const newHeight = Math.max(image.height, pixelCrop.height + Math.abs(pixelCrop.y));

  // Create a canvas element with the new dimensions
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = newWidth;
  canvas.height = newHeight;

  // Fill the canvas with a white background (or transparent if preferred)
  ctx.fillStyle = 'white'; // Change to 'transparent' if you prefer a transparent background
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Calculate offsets to center the original image on the enlarged canvas
  const offsetX = pixelCrop.x < 0 ? -pixelCrop.x : 0;
  const offsetY = pixelCrop.y < 0 ? -pixelCrop.y : 0;

  // Update the pixelCrop values
  const newPixelCrop = {
    x: Math.max(0, pixelCrop.x + offsetX),
    y: Math.max(0, pixelCrop.y + offsetY),
    width: pixelCrop.width,
    height: pixelCrop.height,
  };

  logger.log('enlargeImage: Calculated newPixelCrop:', newPixelCrop);

  // Draw the original image onto the enlarged canvas
  ctx.drawImage(image, offsetX, offsetY, image.width, image.height);

  return {
    canvas,
    newPixelCrop,  // Return the new pixelCrop values
  };
};

export default getCroppedImg;