import { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import logger from '../utils/logger';

/**
 * Custom hook to check if the user is an admin.
 *
 * @returns {boolean} isAdmin - Whether the user is an admin
 */
const useIsAdmin = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        try {
          const idTokenResult = await user.getIdTokenResult();
          setIsAdmin(!!idTokenResult.claims.admin);
          logger.log("useIsAdmin hook, useIsAdmin: Admin status:", !!idTokenResult.claims.admin);
        } catch (error) {
          logger.error('Error checking admin status:', error);
        }
      }
    };

    checkAdminStatus();
  }, []);

  return isAdmin;
};

export default useIsAdmin;