import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import './Dashboard.css';

// Register components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const Dashboard = () => {
    const [eventCounts, setEventCounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const backendUrl = process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_BACKEND_URL_PRODUCTION
        : process.env.REACT_APP_BACKEND_URL_LOCAL;

    useEffect(() => {
        const fetchEventCounts = async () => {
            try {
                const response = await axios.get(`${backendUrl}/dashboard/event_counts`);
                setEventCounts(response.data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchEventCounts();
    }, [backendUrl]);

    const data = {
        labels: eventCounts.map(item => item.month),
        datasets: [
            {
                label: 'Number of Events',
                data: eventCounts.map(item => item.count),
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="dashboard-page">
            <h1>Dashboard</h1>
            <div className="dashboard-card">
                <h2>Monthly Event Counts</h2>
                <div className="chart-container">
                    <Bar data={data} options={options} />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;