// File: ./src/components/common/LoadingSpinner.js

import React from 'react';

const sizeClassMap = {
  1: 'h-1 w-1',
  2: 'h-2 w-2',
  3: 'h-3 w-3',
  4: 'h-4 w-4',
  5: 'h-5 w-5',
  6: 'h-6 w-6',
  8: 'h-8 w-8',
  10: 'h-10 w-10',
  12: 'h-12 w-12',
  16: 'h-16 w-16',
  24: 'h-24 w-24',
};

const LoadingSpinner = ({ size = 6, color = 'text-purple-600' }) => {
  const sizeClass = sizeClassMap[size] || 'h-6 w-6'; // Default to h-6 w-6

  return (
    <div className="flex justify-center items-center h-full">
      <svg
        className={`animate-spin ${sizeClass} ${color}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
        ></path>
      </svg>
    </div>
  );
};

export default LoadingSpinner;