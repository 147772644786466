// src/hooks/useFetchInvitation.js

import { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import logger from '../utils/logger';

const useFetchInvitation = (inviteId) => {
  const [invitationData, setInvitationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInvitation = async () => {
      try {
        const docRef = doc(db, 'community_members', inviteId); // Updated collection to 'community_members'
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setInvitationData({ id: docSnap.id, ...docSnap.data() });
        } else {
          setError('No such invitation found!');
        }
      } catch (err) {
        logger.error('Error fetching invitation:', err);
        setError('Error fetching invitation');
      } finally {
        setLoading(false);
      }
    };

    fetchInvitation();
  }, [inviteId]);

  return { invitationData, loading, error };
};

export default useFetchInvitation;