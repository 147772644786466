// File: src/components/screens/communities/JoinCommunity.js
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { joinCommunity as joinCommunityAction } from '../../../utils/communityActions';
import logger from '../../../utils/logger';

const JoinCommunity = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const joinCommunity = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        try {
          await joinCommunityAction(id, user.uid); // Use utility function
          navigate(`/community/${id}`); // Redirect back to the community details page
        } catch (error) {
          logger.error('Error joining the community:', error);
          navigate(`/community/${id}`); // Redirect to community details even if there's an error
        }
      }
    };

    joinCommunity();
  }, [id, navigate]);

  return <p>Joining community...</p>;
};

export default JoinCommunity;