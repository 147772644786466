// src/contexts/AuthContext.js

import React, { useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebaseConfig';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  getIdTokenResult,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateProfile,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import logger from '../utils/logger';

const AuthContext = React.createContext();

/**
 * Custom hook to use the AuthContext.
 *
 * @returns {Object} Authentication context value
 */
export function useAuth() {
  return useContext(AuthContext);
}

/**
 * AuthProvider component to provide authentication state and functions.
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components
 * @returns {JSX.Element} Auth context provider
 */
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  /**
   * Sign up a new user with email and password.
   *
   * @param {string} email - User email
   * @param {string} password - User password
   * @param {string} name - User display name
   * @throws Will throw an error if signup fails
   */
  async function signup(email, password, name) {
    try {
      logger.log("Starting signup process...");
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      logger.log("User created with UID:", user.uid);
  
      await updateProfile(user, { displayName: name });
      logger.log("User profile updated with name:", name);
  
      const memberRef = doc(db, 'members', user.uid);
      const memberDoc = await getDoc(memberRef);
  
      if (!memberDoc.exists()) {
        logger.log("No existing member document found. Creating new member document.");
        await setDoc(memberRef, {
          displayName: name,
          profilePictureUrl: user.photoURL || '',
          createdAt: new Date(),
          isPublic: true,
        });
        logger.log("Member document created successfully for UID:", user.uid);
      } else {
        logger.log("A member document with this userId already exists.");
      }
    } catch (error) {
      logger.error("Signup error:", error);
      throw error;
    }
  }

  /**
   * Sign up or log in a user with Google.
   *
   * @throws Will throw an error if Google sign-up fails
   */
  async function googleSignup() {
    try {
      const provider = new GoogleAuthProvider();
      logger.log("Starting Google sign-in process...");
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;
  
      logger.log("Google user signed in with UID:", user.uid);
  
      if (!user.displayName) {
        logger.log("User does not have a display name, setting to 'Google User'");
        await updateProfile(user, { displayName: user.displayName || 'Google User' });
      }
  
      // Do not use the user's google profile picture
      // reason for this is that CORS seems to be blocking access to the image
      // I may possibly need to build a server side method to handle this
      let profilePictureUrl = '';
      
      const memberRef = doc(db, 'members', user.uid);
      const memberDoc = await getDoc(memberRef);
  
      if (!memberDoc.exists()) {
        logger.log("No existing member document found for Google user. Creating new member document.");
        await setDoc(memberRef, {
          displayName: user.displayName || 'Google User',
          profilePictureUrl: profilePictureUrl,
          createdAt: new Date(),
          isPublic: true,
        });
        logger.log("Member document created successfully for Google user with UID:", user.uid);
      } else {
        logger.log("A member document with this userId already exists for Google user.");
      }
    } catch (error) {
      logger.error("Google sign-up error:", error.message);
      console.error("Error stack:", error.stack);
      throw error;
    }
  }

  

  /**
   * Log in a user with email and password.
   *
   * @param {string} email - User email
   * @param {string} password - User password
   * @throws Will throw an error if login fails
   */
  async function login(email, password) {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      logger.error("Login error:", error);
      throw error;
    }
  }

  /**
   * Log out the current user.
   *
   * @throws Will throw an error if logout fails
   */
  async function logout() {
    try {
      await signOut(auth);
    } catch (error) {
      logger.error("Logout error:", error);
      throw error;
    }
  }

  /**
   * Send a verification email to the current user.
   *
   * @throws Will throw an error if sending the verification email fails
   */
  async function sendVerificationEmail() {
    if (currentUser) {
      try {
        await sendEmailVerification(currentUser);
      } catch (error) {
        logger.error("Error sending verification email:", error);
      }
    } else {
      logger.error("No current user to send verification email.");
    }
  }

  /**
   * Send a password reset email.
   *
   * @param {string} email - User email
   * @throws Will throw an error if sending the password reset email fails
   */
  async function resetPassword(email) {
    try {
      await sendPasswordResetEmail(auth, email);
      alert('Password reset email sent. Please check your inbox.');
    } catch (error) {
      logger.error("Error sending password reset email:", error);
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      setLoading(false);

      if (user) {
        try {
          const token = await getIdTokenResult(user);
          setIsAdmin(!!token.claims.admin);
          if (typeof window.authStateChangeCallback === 'function') {
            window.authStateChangeCallback();
          }
        } catch (error) {
          logger.error("Error fetching token:", error);
          setIsAdmin(false);
        }
      } else {
        setIsAdmin(false);
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    googleSignup,
    logout,
    isAdmin,
    sendVerificationEmail,
    resetPassword,
    setIsAdmin
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}