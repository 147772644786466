// src/components/common/related-list/RelatedList.js

import React from 'react';

const RelatedList = ({ title, children }) => {
  return (
    <div className="related-list mb-6 w-full max-w-4xl bg-white border-2 border-purple-600 rounded-lg p-6 shadow-lg">
      <h2 className="text-xl font-bold text-purple-800 mb-2">{title}</h2>
      <div className="related-list-content">
        {children}
      </div>
    </div>
  );
};

export default RelatedList;