import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../../contexts/AuthContext';
import { FaUserCircle } from 'react-icons/fa';
import ReusableButton from '../../common/reusable-button/ReusableButton';

const SuggestionDetail = () => {
    const { id } = useParams();
    const { currentUser } = useAuth();
    const [suggestion, setSuggestion] = useState(null);
    const [newComment, setNewComment] = useState('');
    const [editingCommentId, setEditingCommentId] = useState(null);
    const [editingCommentText, setEditingCommentText] = useState('');

    useEffect(() => {
        const fetchSuggestion = async () => {
            const suggestionDoc = doc(db, 'suggestions', id);
            const docSnap = await getDoc(suggestionDoc);
            if (docSnap.exists()) {
                const suggestionData = docSnap.data();
                suggestionData.comments = suggestionData.comments.map(comment => ({
                    ...comment,
                    createdAt: comment.createdAt instanceof Date ? comment.createdAt : comment.createdAt.toDate(),
                }));
                setSuggestion(suggestionData);
            }
        };

        fetchSuggestion();
    }, [id]);

    const handleAddComment = async () => {
        if (!currentUser) return alert('You must be logged in to comment.');

        const newCommentData = {
            userId: currentUser.uid,
            username: currentUser.displayName || 'Anonymous',
            text: newComment,
            createdAt: new Date(),
        };

        const suggestionDoc = doc(db, 'suggestions', id);
        const updatedComments = [...suggestion.comments, newCommentData];

        await updateDoc(suggestionDoc, { comments: updatedComments });
        setSuggestion({ ...suggestion, comments: updatedComments });
        setNewComment('');
    };

    const handleEditComment = async (commentIndex) => {
        const updatedComments = [...suggestion.comments];
        updatedComments[commentIndex].text = editingCommentText;

        const suggestionDoc = doc(db, 'suggestions', id);
        await updateDoc(suggestionDoc, { comments: updatedComments });
        setSuggestion({ ...suggestion, comments: updatedComments });
        setEditingCommentId(null);
        setEditingCommentText('');
    };

    const handleDeleteComment = async (commentIndex) => {
        const updatedComments = suggestion.comments.filter((_, index) => index !== commentIndex);

        const suggestionDoc = doc(db, 'suggestions', id);
        await updateDoc(suggestionDoc, { comments: updatedComments });
        setSuggestion({ ...suggestion, comments: updatedComments });
    };

    if (!suggestion) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="w-full max-w-4xl mx-auto bg-white border-2 border-purple-600 rounded-lg p-6 shadow-lg">
                <h2 className="text-2xl font-bold text-purple-800 mb-4">Suggestion Details</h2>
                <div className="mb-6">
                    <div
                        className="font-semibold whitespace-pre-wrap break-words"
                        dangerouslySetInnerHTML={{ __html: suggestion.text }}
                    />
                    <div className="flex items-center text-sm text-gray-500">
                        {suggestion.userProfilePic ? (
                            <img src={suggestion.userProfilePic} alt="Profile" className="w-6 h-6 rounded-full mr-2" />
                        ) : (
                            <FaUserCircle className="w-6 h-6 mr-2" />
                        )}
                        <span>Suggested by {suggestion.username}</span>
                        <span className="ml-1"> on {new Date(suggestion.createdAt.seconds * 1000).toLocaleString()}</span>
                    </div>
                </div>
                <div>
                    <h3 className="text-xl font-bold text-purple-800 mb-4">Comments</h3>
                    {suggestion.comments.map((comment, index) => (
                        <div key={index} className="mb-4">
                            <div className="flex items-center text-sm text-gray-500 mb-1">
                                <FaUserCircle className="w-5 h-5 mr-2" />
                                {comment.username} on {new Date(comment.createdAt.seconds * 1000).toLocaleString()}
                                {comment.userId === currentUser?.uid && (
                                    <div className="ml-2 space-x-2">
                                        <button
                                            onClick={() => {
                                                setEditingCommentId(index);
                                                setEditingCommentText(comment.text);
                                            }}
                                            className="text-blue-500"
                                        >
                                            Edit
                                        </button>
                                        <button
                                            onClick={() => handleDeleteComment(index)}
                                            className="text-red-500"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                )}
                            </div>
                            {editingCommentId === index ? (
                                <div>
                                    <textarea
                                        value={editingCommentText}
                                        onChange={(e) => setEditingCommentText(e.target.value)}
                                        className="w-full h-32 p-2 border border-gray-300 rounded-md"
                                    />
                                    <div className="flex justify-end mt-2">
                                        <ReusableButton onClick={() => handleEditComment(index)}>
                                            Save
                                        </ReusableButton>
                                    </div>
                                </div>
                            ) : (
                                <p>{comment.text}</p>
                            )}
                        </div>
                    ))}
                </div>
                <div className="mt-6">
                    <h3 className="text-xl font-bold text-purple-800 mb-4">Add a Comment</h3>
                    <textarea
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        className="w-full h-32 p-2 border border-gray-300 rounded-md"
                    />
                    <div className="flex justify-end mt-4">
                        <ReusableButton onClick={handleAddComment}>
                            Add Comment
                        </ReusableButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuggestionDetail;
