import { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useAuth } from '../contexts/AuthContext';
import logger from '../utils/logger';

const useAcceptCommunityInvitation = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const acceptInvitation = async (communityId, inviteId) => {
    if (!currentUser) {
      setError('User is not logged in');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const functions = getFunctions();
      const acceptInvite = httpsCallable(functions, 'acceptCommunityInvitation');
      const response = await acceptInvite({ communityId, inviteId });

      if (response.data.success) {
        logger.log('Invitation accepted and processed successfully.');
        return { success: true };
      } else {
        setError('Failed to accept the invitation.');
        return { success: false };
      }
    } catch (err) {
      logger.error('Error accepting invitation:', err);
      setError('Failed to accept the invitation.');
      return { success: false, error: 'Failed to accept the invitation.' };
    } finally {
      setLoading(false);
    }
  };

  return { acceptInvitation, loading, error };
};

export default useAcceptCommunityInvitation;