// src/components/communities/CommunityRelatedLists.js

import React from 'react';
import RelatedList from '../../common/related-list/RelatedList';
import CommunityOrganizers from './CommunityOrganizers';
import CommunityMembers from './CommunityMembers';
import CommunitySuggestions from './CommunitySuggestions';
import CommunityInvites from './CommunityInvites';

const CommunityRelatedLists = ({ groupId, communityId, isLoggedIn, isAdmin, isMember, isOwner }) => {
  return (
    <>
      {isLoggedIn && isAdmin && (
        <RelatedList title="Organizers">
          <CommunityOrganizers groupId={groupId} />
        </RelatedList>
      )}
      {isLoggedIn && (isAdmin || isOwner) && (
        <RelatedList title="Invites">
          <CommunityInvites communityId={communityId} />
        </RelatedList>
      )}
      {isLoggedIn && (isAdmin || isMember) && (
        <>
        <RelatedList title="Members">
          <CommunityMembers communityId={communityId} />
        </RelatedList>
        <RelatedList title="Suggestions">
          <CommunitySuggestions communityId={communityId} />
        </RelatedList>
        </>
      )}
    </>
  );
};

export default CommunityRelatedLists;