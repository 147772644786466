// File: ./src/components/Members/Members.js

import React, { useEffect, useState } from 'react';
import { db } from '../../../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import './Members.css';

const Members = () => {
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMembers = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'members'));
                const membersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setMembers(membersData);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchMembers();
    }, []);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const sortedMembers = [...members].sort((a, b) => a.name.localeCompare(b.name));

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="members-main-content">
            <h1>Members</h1>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Bio</th>
                            <th>Group</th>
                            <th>Created At</th>
                            <th>Photo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedMembers.map((member, index) => (
                            <tr key={index}>
                                <td>
                                    <Link to={`/member/${member.id}`}>{member.name}</Link>
                                </td>
                                <td>{member.bio}</td>
                                <td>{member.groupName}</td>
                                <td>{formatDate(member.createdAt)}</td>
                                <td>
                                    {member.memberPhoto && <img src={member.memberPhoto.replace('1200x675', '32x32')} alt={member.name} />}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Members;