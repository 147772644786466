import React, { useEffect, useState } from 'react';
import { db } from '../../../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import logger from '../../../utils/logger';

const CommunityOrganizers = ({ groupId }) => {
    const [organizers, setOrganizers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOrganizers = async () => {
            try {
                logger.log("Starting to fetch organizers...");

                const q = query(collection(db, 'community_organizers'), where('groupId', '==', groupId));
                const querySnapshot = await getDocs(q);
                logger.log("Fetched community_organizers:", querySnapshot.docs.map(doc => doc.data()));

                const organizersData = [];

                for (const docSnap of querySnapshot.docs) {
                    const organizerData = docSnap.data();
                    logger.log("Processing organizer data:", organizerData);

                    const memberRef = collection(db, 'members');
                    const memberQuery = query(memberRef, where("memberId", "==", organizerData.memberId));
                    const memberSnapshot = await getDocs(memberQuery);

                    if (!memberSnapshot.empty) {
                        const memberData = memberSnapshot.docs[0].data();
                        logger.log("Fetched member data:", memberData);

                        organizersData.push({ ...organizerData, member: memberData });
                    } else {
                        logger.log("No member data found for memberId:", organizerData.memberId);
                        organizersData.push(organizerData);
                    }
                }

                setOrganizers(organizersData);
                logger.log('Organizers data set:', organizersData);
            } catch (err) {
                logger.error("Error fetching organizers:", err);
                setError(err.message);
            } finally {
                setLoading(false);
                logger.log("Finished fetching organizers.");
            }
        };

        fetchOrganizers();
    }, [groupId]);

    if (loading) return <p className="text-xl text-purple-600">Loading organizers...</p>;
    if (error) return <p className="text-xl text-red-600">Error: {error}</p>;

    if (organizers.length === 0) return <p>No organizers found for this community.</p>;

    return (
        <ul>
            {organizers.map((organizer) => (
                <li key={organizer.id} className="flex items-center py-4 border-b border-gray-200 last:border-0 px-4">
                    {organizer.member ? (
                        <Link to={`/member/${organizer.member.memberId}`} className="flex items-center w-full no-underline hover:bg-gray-100 transition-colors duration-300 p-2 rounded-lg">
                            {organizer.member.memberPhoto && (
                                <img src={organizer.member.memberPhoto} alt={organizer.member.name} className="w-10 h-10 rounded-full mr-4" />
                            )}
                            <span className="flex-1 text-gray-700">{organizer.member.name}</span>
                            <span className="flex-1 text-gray-700">{organizer.memberId}</span>
                            <span className="flex-1 text-gray-700">{organizer.status}</span>
                        </Link>
                    ) : (
                        <>
                            <span className="flex-1 text-gray-700">{organizer.memberId}</span>
                            <span className="flex-1 text-gray-700">{organizer.status}</span>
                        </>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default CommunityOrganizers;