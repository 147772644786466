// src/components/EditCommunity.js

import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CommunityForm from './CommunityForm';
import useFetchCommunity from '../../../hooks/useFetchCommunity';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';
import ReusableButton from '../../common/reusable-button/ReusableButton';
import logger from '../../../utils/logger';

const EditCommunity = () => {
  const { id } = useParams();
  const { community, loading, error } = useFetchCommunity(id);
  const navigate = useNavigate();

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this community? This action cannot be undone.')) {
      try {
        await deleteDoc(doc(db, 'communities', id));
        logger.log('Community deleted successfully!');
        navigate('/communities'); // Redirect to communities list after deletion
      } catch (error) {
        logger.error('Error deleting community:', error);
        alert('Error deleting community. Please try again later.');
      }
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="flex flex-col items-center mt-6 px-4 py-4">
      <h1 className="text-3xl font-bold text-purple-700 mb-4">Edit Community</h1>
      <div className="w-full max-w-6xl bg-white border-2 border-purple-600 rounded-lg p-6 shadow-lg">
        {community && <CommunityForm communityData={community} isEditing={true} />}
        <div className="text-right mt-4">
          <ReusableButton onClick={handleDelete} type="delete">
            Delete Community
          </ReusableButton>
        </div>
      </div>
    </div>
  );
};

export default EditCommunity;