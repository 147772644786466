import React from 'react';
import Select from 'react-select';

const CommunitySelect = ({ communities, onChange }) => (
    <div>
        <label className="block text-lg font-bold mb-2">Community (Optional)</label>
        <Select
            isMulti
            options={communities}
            onChange={onChange}
            className="w-full p-2 border border-gray-300 rounded-md"
        />
    </div>
);

export default CommunitySelect;
