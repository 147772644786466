import React, { useState, useEffect } from 'react';
import { FaUserPlus, FaTrash } from 'react-icons/fa';
import ReusableButton from '../../common/reusable-button/ReusableButton';
import { db } from '../../../firebaseConfig';
import { collection, getDocs, query, where, deleteDoc, doc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const CommunityInvites = ({ communityId }) => {
  const [email, setEmail] = useState('');
  const [pendingInvites, setPendingInvites] = useState([]); // Initialized as an empty array
  const [errorMessage, setErrorMessage] = useState(''); // State to hold error messages

  const fetchPendingInvites = async () => {
    const q = query(
      collection(db, 'community_members'),
      where('communityId', '==', communityId),
      where('status', '==', 'invited')
    );
    const querySnapshot = await getDocs(q);
    const invitesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setPendingInvites(invitesData);
  };

  useEffect(() => {
    fetchPendingInvites();
  }, [communityId]);

  const handleInvite = async () => {
    if (email) {
        const functions = getFunctions();
        const inviteMember = httpsCallable(functions, 'handleNewInvite');
        
        try {
            const result = await inviteMember({
                communityId: communityId,
                email: email,
                communityName: "Your Community Name"  // Add community name here
            });
            console.log("Function response:", result.data); // Ensure we are logging the correct data
            
            // Check the success status inside inviteData
            if (result.data.inviteData && result.data.inviteData.success === false) {
                // Display the error message returned by the function
                setErrorMessage(result.data.inviteData.error); // This should update the UI
                console.log("Error from function:", result.data.inviteData.error); // Log the error
            } else if (result.data && result.data.success) {
                // Refetch the pending invites after successfully sending an invite
                await fetchPendingInvites();
                
                // Clear the input and any previous error messages
                setEmail('');
                setErrorMessage('');
            } else {
                // Handle unexpected response structure
                setErrorMessage("An unexpected error occurred. Please try again.");
                console.error("Unexpected response structure:", result.data);
            }
        } catch (error) {
            console.error("Error sending invite:", error);
            setErrorMessage("An unexpected error occurred. Please try again.");
        }
    }
};

  const handleRemoveInvite = async (inviteId) => {
    try {
      await deleteDoc(doc(db, 'community_members', inviteId));
      setPendingInvites(pendingInvites.filter(invite => invite.id !== inviteId));
    } catch (error) {
      console.error("Error removing invite:", error);
    }
  };

  return (
    <div className="space-y-2">
        <div className="flex items-center space-x-2">
            <input
                type="email"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none"
                placeholder="Invite member by email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <div className="mt-4">
                <ReusableButton onClick={handleInvite}>
                    Send Invite
                </ReusableButton>
            </div>
        </div>
        {errorMessage && (
            <div className="mt-2 text-red-500">
                {errorMessage}
            </div>
        )}
        <ul className="text-sm text-gray-700 mt-4">
            {pendingInvites.map((invite, index) => (
                <li key={index} className="py-2 border-b border-gray-200 last:border-0 flex items-center justify-between">
                    <span>
                        <FaUserPlus className="mr-2 text-gray-500" /> {invite.email} - <span className="text-purple-600 ml-2">{invite.status}</span>
                    </span>
                    <button onClick={() => handleRemoveInvite(invite.id)} className="text-red-500 hover:text-red-700">
                        <FaTrash />
                    </button>
                </li>
            ))}
        </ul>
        {pendingInvites.length === 0 && (
            <div className="text-sm text-gray-500">No pending invites. Use the form above to invite members.</div>
        )}
    </div>
);
};

export default CommunityInvites;