// src/hooks/useFetchCommunities.js

import { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs, getDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import logger from '../utils/logger';

const useFetchCommunities = (communityType = 'all') => {
  const { currentUser, isAdmin } = useAuth();
  const [communities, setCommunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCommunities = async () => {
      try {
        let communitiesData = [];
        logger.log("We reached fetchCommunities...");

        if (communityType === 'all') {
          if (isAdmin) {
            // Fetch all communities if user is admin
            logger.log("Fetching all communities for admin...");
            const allCommunitiesQuery = query(collection(db, 'communities'));
            const allCommunitiesSnapshot = await getDocs(allCommunitiesQuery);
            communitiesData = allCommunitiesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          } else {
            // Fetch public and MEETUP.COM communities for non-admin users
            logger.log("Fetching public and MEETUP.COM communities...");
            const publicAndMeetupQuery = query(
              collection(db, 'communities'),
              where("status", "in", ["PUBLIC", "MEETUP.COM"])
            );
            const publicAndMeetupSnapshot = await getDocs(publicAndMeetupQuery);
            communitiesData = publicAndMeetupSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          }
          logger.log("Fetched communities:", communitiesData);
        } else if (communityType === 'owned' && currentUser) {
          // Fetch owned communities
          logger.log("Fetching owned communities...");
          const ownerQuery = query(collection(db, 'communities'), where("ownerId", "==", currentUser.uid));
          const ownerSnapshot = await getDocs(ownerQuery);
          ownerSnapshot.forEach(doc => {
            if (!communitiesData.some(community => community.id === doc.id)) {
              communitiesData.push({ id: doc.id, ...doc.data() });
            }
          });
          logger.log("Fetched owned communities:", communitiesData);
        } else if (communityType === 'member' && currentUser) {
          // Fetch communities from community_members documents
          logger.log("Fetching communities where user is a member...");
          const memberConnectionQuery = query(collection(db, 'community_members'), where("memberId", "==", currentUser.uid));
          const memberConnectionSnapshot = await getDocs(memberConnectionQuery);

          if (!memberConnectionSnapshot.empty) {
            for (const doc of memberConnectionSnapshot.docs) {
              const communityId = doc.data().communityId;
              const communityDoc = await getDoc(doc(db, 'communities', communityId));
              if (communityDoc.exists() && communityDoc.data().status !== "DRAFT") {
                communitiesData.push({ id: communityDoc.id, ...communityDoc.data() });
              }
            }
          }
          logger.log("Fetched community_members documents:", communitiesData);
        }

        setCommunities(communitiesData);
      } catch (err) {
        logger.error("Error fetching communities:", err);
        setError(`Error fetching communities: ${err.message}`);
      } finally {
        setLoading(false);
        logger.log("Finished fetching communities");
      }
    };

    fetchCommunities();
  }, [communityType, currentUser, isAdmin]);

  return { communities, loading, error };
};

export default useFetchCommunities;