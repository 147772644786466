// File: ./src/App.js

import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import EventList from './components/screens/events/EventList';
import EventDetails from './components/screens/events/EventDetails';
import NewEvent from './components/screens/events/NewEvent'; 
import EditEvent from './components/screens/events/EditEvent';
import NewCommunity from './components/screens/communities/NewCommunity';
import EditCommunity from './components/screens/communities/EditCommunity';
import Login from './components/screens/login/Login';
import SignUp from './components/screens/login/SignUp';
import About from './components/StaticContent/About';
import BecomeMember from './components/StaticContent/BecomeMember';
import Members from './components/screens/members/Members';
import MemberDetails from './components/screens/members/MemberDetails';
import FAQ from './components/StaticContent/FAQ';
import CommunityList from './components/screens/communities/CommunityList';
import CommunityScreen from './components/screens/communities/CommunityScreen';
import JoinCommunity from './components/screens/communities/JoinCommunity';
import Dashboard from './components/screens/dashboard/Dashboard'; 
import UserProfileScreen from './components/screens/user-profile/UserProfileScreen';
import SuccessPage from './components/common/oauth-connections/SuccessPage';
import ErrorPage from './components/common/oauth-connections/ErrorPage';
import Suggestions from './components/screens/suggestions/Suggestions';
import SuggestionDetail from './components/screens/suggestions/SuggestionDetail';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import ForgotPassword from './components/screens/login/ForgotPassword';
import PrivateRoute from './routes/PrivateRoute';
import logger from './utils/logger';
import HamburgerMenu from './components/common/HamburgerMenu';
import CommunityAcceptInvitation from './components/screens/communities/CommunityAcceptInvitation';
import './styles/Global.css';


/**
 * Main application component that provides routes and navigation for the application.
 * 
 * @component
 * @returns {JSX.Element} The main application component.
 */
const App = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [events, setEvents] = useState([]); // Store events state here


    // Log the deployed environment
    logger.log('Deployed Environment (REACT_APP_ENV):', process.env.REACT_APP_ENV);
    logger.log('Node Environment (NODE_ENV):', process.env.NODE_ENV);

    const toggleMenu = () => {
        logger.log('Menu Toggled: ' + isMenuOpen)
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <AuthProvider>
            <Router>
                <div id="root" className="bg-rppLightGrey min-h-screen">
                    <HamburgerMenu></HamburgerMenu>
                    {/*<HeaderTailwind isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />*/}
                    {/*<Header isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />*/}
                    <main className="main-content">
                        <Routes>
                            <Route path="/" element={<EventList setEvents={setEvents} />} />
                            <Route path="/events" element={<EventList setEvents={setEvents} />} />
                            <Route path="/event/:id" element={<EventDetails events={events} />} />
                            <Route path="/new-event" element={<PrivateRoute><NewEvent /></PrivateRoute>} />
                            <Route path="/edit-event/:id" element={<PrivateRoute><EditEvent /></PrivateRoute>} />
                            <Route path="/new-community" element={<PrivateRoute><NewCommunity /></PrivateRoute>} />
                            <Route path="/edit-community/:id" element={<PrivateRoute><EditCommunity /></PrivateRoute>} /> 
                            <Route path="/signup" element={<SignUp />} />
                            <Route path="/profile" element={<PrivateRoute><UserProfileScreen /></PrivateRoute>} /> 
                            <Route path="/about" element={<About />} />
                            <Route path="/become-member" element={<BecomeMember />} />
                            <Route path="/faq" element={<FAQ />} />
                            <Route path="/communities" element={<CommunityList />} />
                            <Route path="/community/:id" element={<CommunityScreen />} />
                            <Route path="/community/join/:id" element={<PrivateRoute><JoinCommunity /></PrivateRoute>} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/auth/success" element={<SuccessPage />} />
                            <Route path="/auth/error" element={<ErrorPage />} />
                            <Route path="/members" element={<PrivateRoute><Members /></PrivateRoute>} />
                            <Route path="/member/:id" element={<PrivateRoute><MemberDetails /></PrivateRoute>} />
                            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                            <Route path="/suggestions" element={<Suggestions />} />
                            <Route path="/suggestions/:id" element={<SuggestionDetail />} />
                            <Route path="/forgot-password" element={<ForgotPassword />} /> 
                            <Route path="/accept-invite/:inviteId" element={<CommunityAcceptInvitation />} />
                        </Routes>
                    </main>
                </div>
            </Router>
        </AuthProvider>
    );
};

export default App;