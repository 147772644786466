// src/components/ConfirmModal.js

import React from 'react';
import PropTypes from 'prop-types';

const ConfirmModal = ({ show, onClose, onConfirm, title, message, confirmText = "Yes", cancelText = "No", cancelButton = true }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-black opacity-50 absolute inset-0"></div>
            <div className="bg-white p-6 rounded-lg shadow-lg z-10">
                <h2 className="text-2xl font-bold mb-4">{title}</h2>
                <p className="mb-6">{message}</p>
                <div className="flex justify-end">
                    {cancelButton && (
                        <button
                            className="bg-gray-500 text-white px-4 py-2 rounded-md mr-4"
                            onClick={onClose}
                        >
                            {cancelText}
                        </button>
                    )}
                    <button
                        className="bg-red-600 text-white px-4 py-2 rounded-md"
                        onClick={onConfirm}
                    >
                        {confirmText}
                    </button>
                </div>
            </div>
        </div>
    );
};

ConfirmModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    cancelButton: PropTypes.bool,
};

export default ConfirmModal;
