// File: ./src/components/ForgotPassword/ForgotPassword.js

import React, { useRef, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMessage('');
      setError('');
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMessage('Check your inbox for further instructions');
    } catch {
      setError('Failed to reset password');
    }

    setLoading(false);
  }

  return (
    <div className="flex flex-col items-center justify-start min-h-screen" style={{ backgroundColor: 'rgb(240, 240, 240)' }}>
      <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-md mt-10">
        <h2 className="text-2xl font-bold text-center rppPurple mb-4">Password Reset</h2>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        {message && <p className="text-green-500 text-center mb-4">{message}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="email"
            ref={emailRef}
            required
            placeholder="Email"
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
          />
          <button
            disabled={loading}
            type="submit"
            className="w-full bg-purple-700 text-white py-2 rounded-md font-semibold hover:bg-purple-800"
          >
            Reset Password
          </button>
        </form>
        <div className="text-center mt-4">
          <Link to="/login" className="rppPurple hover:underline">
            Log In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;