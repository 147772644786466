import React from 'react';
import { FaLock, FaGlobe, FaEyeSlash, FaFileAlt, FaQuestionCircle, FaMeetup } from 'react-icons/fa';

const statusIcons = {
  DRAFT: FaFileAlt,
  HIDDEN: FaEyeSlash,
  PRIVATE: FaLock,
  PUBLIC: FaGlobe,
  MEETUP_COM: FaMeetup, // Using an underscore since the key cannot contain a dot
  NOT_SPECIFIED: FaQuestionCircle,
};

const CommunityStatus = ({ status }) => {
  // Replace dot with underscore to match the key in the statusIcons object
  const formattedStatus = status ? status.replace('.', '_') : 'NOT_SPECIFIED';
  const StatusIcon = statusIcons[formattedStatus] || statusIcons.NOT_SPECIFIED;

  return (
    <div className="flex items-center text-gray-700 text-lg mb-2">
      <span className="font-semibold mr-2">Community Type:</span>
      <StatusIcon className="mr-2" />
      <span>{status || 'Not Specified'}</span>
    </div>
  );
};

export default CommunityStatus;