import React, { useEffect, useState } from 'react';
import { db } from '../../../firebaseConfig';
import { collection, query, getDocs, where } from 'firebase/firestore';
import { useAuth } from '../../../contexts/AuthContext';
import EventTabs from './EventTabs';
import EventGroup from './EventGroup';
import { parseDuration } from '../../../utils/utils';
import logger from '../../../utils/logger';

const EventList = ({ setEvents }) => {
    const [events, setLocalEvents] = useState([]);
    const [tab, setTab] = useState('upcoming');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { currentUser, isAdmin } = useAuth();
    const [currentPage, setCurrentPage] = useState(1);
    const [eventsPerPage] = useState(5); // Fixed number of events per page for now

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                logger.log("Fetching events from Firestore...");
                let q;

                if (tab === 'drafts' && currentUser) {
                    q = query(collection(db, 'events'), 
                        where('status', '==', 'DRAFT'), 
                        where('ownerId', '==', currentUser.uid)
                    );
                } else {
                    q = query(collection(db, 'events'), 
                        where('ownerId', '==', 'meetup-integration')
                    );
                }

                const querySnapshot = await getDocs(q);
                logger.log("Query snapshot:", querySnapshot);

                // Get all events and sort them
                let eventsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                logger.log("Fetched events data:", eventsData);

                // Sort events by dateTime in descending order
                eventsData = eventsData.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));

                // Paginate the events
                const startIndex = (currentPage - 1) * eventsPerPage;
                const paginatedEvents = eventsData.slice(startIndex, startIndex + eventsPerPage);

                setLocalEvents(eventsData);
                setEvents(eventsData);
            } catch (err) {
                logger.error("Error fetching events:", err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchEvents();
    }, [setEvents, tab, currentUser]);

    const formatDate = (dateString) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const upcomingEvents = events
        .filter(event => {
            const currentDate = new Date();
            const eventDate = new Date(event.dateTime);
            const isUpcoming = eventDate > currentDate;
            const isNotDraft = event.status !== 'DRAFT';
            logger.log(`Event: ${event.title}, dateTime: ${event.dateTime}, eventDate: ${eventDate}, currentDate: ${currentDate}, isUpcoming: ${isUpcoming}, isNotDraft: ${isNotDraft}`);
            return isUpcoming && isNotDraft;
        })
        .sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime)); // Sort ascending

    const pastEvents = events
        .filter(event => new Date(event.dateTime) <= new Date() && event.status !== 'DRAFT')
        .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime)); // Sort descending

    const draftEvents = events
        .filter(event => event.status === 'DRAFT')
        .sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime)); // Sort ascending

    logger.log("Upcoming Events:", upcomingEvents);
    logger.log("Past Events:", pastEvents);
    logger.log("Draft Events:", draftEvents);

    const groupEventsByMonth = (events) => {
        const groupedEvents = [];
        let currentMonth = '';

        events.forEach(event => {
            const eventMonth = new Date(event.dateTime).toLocaleDateString(undefined, { month: 'long', year: 'numeric' });

            if (eventMonth !== currentMonth) {
                groupedEvents.push({ type: 'month', value: eventMonth });
                currentMonth = eventMonth;
            }

            groupedEvents.push({ type: 'event', value: event });
        });

        return groupedEvents;
    };

    const eventsToDisplay = groupEventsByMonth(tab === 'drafts' ? draftEvents : tab === 'upcoming' ? upcomingEvents : pastEvents);

    logger.log("Events to Display:", eventsToDisplay);

    return (
        <div className="flex flex-col items-center py-4 mx-4 md:mx-0">
            <EventTabs tab={tab} setTab={setTab} currentUser={currentUser} isAdmin={isAdmin} />
            <h1 className="mt-4 text-3xl font-bold text-purple-700">
                {tab === 'upcoming' ? 'Upcoming Events' : tab === 'drafts' ? 'Draft Events' : 'Past Events'}
            </h1>
            {loading && <p className="text-xl text-purple-600">Loading...</p>}
            {error && <p className="text-xl text-red-600">Error: {error}</p>}
            {!loading && !error && eventsToDisplay.map((item, index) => (
                <EventGroup key={index} item={item} formatDate={formatDate} parseDuration={parseDuration} />
            ))}
            {!loading && !error && eventsToDisplay.length === 0 && (
                <p className="text-xl text-purple-600">No upcoming events found.</p>
            )}
        </div>
    );
};

export default EventList;
