// src/components/common/screen-buton/ScreenButton.js
import React from 'react';

const ScreenButton = ({ children, onClick, active = false }) => {
  return (
    <button
      onClick={onClick}
      className={`py-2 px-4 rounded-md font-semibold transition-colors duration-300 ${
        active
          ? 'bg-purple-600 text-white'
          : 'bg-white border-2 border-purple-600 text-purple-600 hover:bg-purple-600 hover:text-white'
      }`}
      style={{ height: '40px' }} // Adjust this value to match the height of "+ New Community" button
    >
      {children}
    </button>
  );
};

export default ScreenButton;