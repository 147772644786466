import React from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../../../contexts/AuthContext'; // Correct import for useAuth
import { getAuth } from 'firebase/auth'; // Correct import for getAuth
import logger from '../../../utils/logger';

const ConnectWithMeetup = () => {
  const { isAdmin } = useAuth(); // Get isAdmin from useAuth

  // Only render the component if the user is an admin
  if (!isAdmin) {
    return null;
  }

  const clientId = process.env.REACT_APP_MEETUP_CLIENT_ID;
  const backendUrl = process.env.REACT_APP_BACKEND_URL_PRODUCTION;
  const redirectUri = `${backendUrl}/auth/callback`;

  const handleConnectClick = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      logger.error('User must be logged in to connect with Meetup.');
      return;
    }

    const state = generateRandomString(16);

    fetch(`${backendUrl}/api/store-state`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ state, user_id: user.uid }),
    }).then(() => {
      const authorizationUrl = `https://secure.meetup.com/oauth2/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&state=${state}`;
      window.open(authorizationUrl, 'MeetupOAuth', 'width=600,height=700');
    });
  };

  return (
    <button
      onClick={handleConnectClick}
      className="inline-block bg-white border-2 border-purple-600 text-purple-600 font-bold py-2 px-4 rounded-md transition-colors duration-300 hover:bg-purple-600 hover:text-white text-center mt-6"
    >
      <img
        src="https://cdn-images-1.medium.com/v2/resize:fit:1600/1*EK8I8k19h9V04NnyO59C_A.png"
        alt="Meetup"
        className="w-6 h-6 inline mr-2"
      />
      Connect with Meetup
    </button>
  );
};

const generateRandomString = (length) => {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return result;
};

ConnectWithMeetup.propTypes = {
  community: PropTypes.object,
};

export default ConnectWithMeetup;