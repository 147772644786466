import React, { useState } from 'react';
import ReusableButton from '../../common/reusable-button/ReusableButton';
import Modal from '../../common/modals/Modal';
import ConfirmModal from '../../common/modals/ConfirmModal';
import LoginComponent from '../../common/auth-components/LoginComponent';
import SignUpComponent from '../../common/auth-components/SignUpComponent';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { deleteDoc, doc } from 'firebase/firestore';
import { auth, db } from '../../../firebaseConfig';
import logger from '../../../utils/logger';

const CommunityDetailsButtons = ({ isLoggedIn, isAdmin, isMember, community, onMemberChange }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogin] = useState(true);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleModalClose = () => setIsModalOpen(false);

  const renderModalContent = () => {
    if (isLogin) {
      return <LoginComponent onClose={handleModalClose} redirectTo={location.pathname} onLoginSuccess={onMemberChange} />;
    }
    return <SignUpComponent />;
  };

  const handleJoinClick = () => {
    if (isLoggedIn) {
      navigate(`/community/join/${community.id}`);
      onMemberChange(); // Trigger re-render when user joins the community
    } else {
      localStorage.setItem('postLoginAction', JSON.stringify({ action: 'join', communityId: community.id }));
      localStorage.setItem('postLoginRedirect', location.pathname);
      setIsModalOpen(true);
    }
  };

  const handleLeaveClick = () => {
    if (isLoggedIn && isMember) {
      setIsConfirmModalOpen(true);
    }
  };

  const confirmLeaveCommunity = async () => {
    try {
      const userId = auth.currentUser.uid;
      await deleteDoc(doc(db, 'community_members', `${community.id}_${userId}`));
      logger.log(`Successfully left community ${community.id}`);
      setIsConfirmModalOpen(false);
      onMemberChange(); // Trigger a state change in parent component to re-fetch data
    } catch (error) {
      logger.error('Error leaving the community:', error);
    }
  };

  return (
    <div className="flex flex-col items-end space-y-4 w-full">
      {community.status === 'MEETUP.COM' && (
        <ReusableButton onClick={() => window.open(community.communityUrl, '_blank')}>
          Join Community on Meetup
        </ReusableButton>
      )}
      {isLoggedIn && isMember && (
        <ReusableButton onClick={handleLeaveClick}>Leave Community</ReusableButton>
      )}
      {isLoggedIn && !isMember && (community.status === 'PUBLIC' || community.status === 'PRIVATE') && (
        <ReusableButton onClick={handleJoinClick}>
          {community.status === 'PUBLIC' ? 'Join Community' : 'Request to Join Community'}
        </ReusableButton>
      )}
      {!isLoggedIn && (
        <ReusableButton onClick={handleJoinClick}>Join Community</ReusableButton>
      )}
      {isAdmin && (
        <Link to={`/edit-community/${community.id}`}>
          <ReusableButton>Edit Community</ReusableButton>
        </Link>
      )}

      <Modal isOpen={isModalOpen} onClose={handleModalClose}>
        {renderModalContent()}
      </Modal>

      <ConfirmModal
        show={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={confirmLeaveCommunity}
        title="Leave Community"
        message="Are you sure you want to leave this community?"
        confirmText="Yes"
        cancelText="No"
      />
    </div>
  );
};

export default CommunityDetailsButtons;