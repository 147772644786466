import React from 'react';
import { Link } from 'react-router-dom';

const EventTabs = ({ tab, setTab, currentUser, isAdmin }) => {
    return (
        <div className="w-full max-w-4xl mx-auto flex justify-end px-0">
            <div className="flex space-x-2">
                <button
                    className={`px-4 py-2 rounded-md ${tab === 'upcoming' ? 'bg-purple-600 text-white' : 'bg-gray-200 text-black'}`}
                    onClick={() => setTab('upcoming')}
                >
                    Upcoming Events
                </button>
                <button
                    className={`px-4 py-2 rounded-md ${tab === 'past' ? 'bg-purple-600 text-white' : 'bg-gray-200 text-black'}`}
                    onClick={() => setTab('past')}
                >
                    Past Events
                </button>
                {currentUser && (
                    <button
                        className={`px-4 py-2 rounded-md ${tab === 'drafts' ? 'bg-purple-600 text-white' : 'bg-gray-200 text-black'}`}
                        onClick={() => setTab('drafts')}
                    >
                        Draft Events
                    </button>
                )}
            </div>
            {isAdmin && (
                <Link to="/new-event" className="ml-auto">
                    <button className="bg-purple-600 text-white py-2 px-4 rounded-md shadow-md hover:bg-purple-700 transition-colors duration-300">
                        + New Event
                    </button>
                </Link>
            )}
        </div>
    );
};

export default EventTabs;
