import React, { useState, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import ImageStats from './ImageStats';
import ImagePreview from './ImagePreview';
import ImageUploadControls from './ImageUploadControls';
import ImageUploadButton from './ImageUploadButton';
import useImageUpload from '../../../hooks/useImageUpload';
import logger from '../../../utils/logger';
import getCroppedImg from '../../../utils/getCroppedImg';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import { v4 as uuidv4 } from 'uuid';

const ImageUpload = forwardRef(({
  label,
  imageSrc,
  onImageSave,
  fileType,
  imageId,  // Pass the communityId or userId or null
  cropShape = 'rect',
  aspect = 16 / 9,
  recommendedResolution = '1200 x 675',
  allowSave = true,
}, ref) => {

  // because props are read only in react, we take imageSrc and assign it to localImageSrc
  const [localImageSrc, setLocalImageSrc] = useState(imageSrc || '');
  // Generate a local ID using random number if no imageId is provided otherwise use imageId
  const [localImageId, setLocalImageId] = useState(imageId || uuidv4());

  // State for cropping and zooming
  // state variable crop which contains the real time position of crop window
  // state variable zoom which contains the zoom level
  // state variable croppedAreaPixels which after user stops moving crop
  // stores the actual final resting place of crop window
  // we will use croppedAreaPixels mainly
  // gets populated with "onCropComplete"
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // access to methods to upload and remove uploaded images
  const { startUpload, startRemove, uploading, error } = useImageUpload();

  // Update localImageSrc when imageSrc changes
  useEffect(() => {
    if (imageSrc) {
      setLocalImageSrc(imageSrc);
    }
  }, [imageSrc]);
  
  // function called when user stops moving the crop area
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    logger.log("croppedArea: " + JSON.stringify(croppedArea));
    logger.log("croppedAreaPixels: " + JSON.stringify(croppedAreaPixels));
    setCroppedAreaPixels(croppedAreaPixels);
  };

  // function called when user clicks the remove image button
  const handleImageRemove = () => {
    logger.log("Image removed");
    setLocalImageSrc(null); // Clear the image source when removing the image
  };

  // gets called when the user presses the "reset" button
  const handleReset = () => {
    setZoom(1); // Reset zoom to default
    setCrop({ x: 0, y: 0 }); // Reset crop to default
    setCroppedAreaPixels(null); // Clear cropped area pixels
  };

  // function called when the user changes the image in the tool
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setLocalImageSrc(reader.result); // Set the image source to the uploaded file
      };
      reader.readAsDataURL(file);
    }
  };

/**
 * Handles the save action by cropping the image and calling the onCropComplete callback.
 */
const handleSave = async () => {
  // firstly, check if there is no image which means the user wants to remove the image
  if (!localImageSrc) {
      logger.log('No image source provided.');
      await startRemove(fileType); // Remove the profile picture

      // Check if onImageSave callback is passed and invoke it
      if (onImageSave) {
          onImageSave(null); // Indicate that no image is saved
      }

      return null; // Explicitly return null as URL when no image is provided
  }

  // now we try the case where an image is provided
  try {
      logger.log("ImageUpload, handleSave: croppedAreaPixels: " + croppedAreaPixels)
      logger.log("ImageUpload, handleSave: zoom: " + zoom)
      logger.log("ImageUpload, handleSave: crop:", crop);
      
      // now we attempt to perform the actual crop and receive a blob in return
      const croppedImageBlob = await getCroppedImg(localImageSrc, croppedAreaPixels);
      logger.log("ImageUpload, handleSave: croppedImageBlob populated");

      // now we try and upload the blob to storage and receive a URL
      logger.log("ImageUpload, handleSave: imageId: " + localImageId);
      const downloadURL = await startUpload(croppedImageBlob, fileType, localImageId);
      logger.log("ImageUpload, handleSave: Download url:", downloadURL);
      
      // then we update the localImageSrc with the new downloaded url
      setLocalImageSrc(downloadURL);

      // Check if onImageSave callback is passed and invoke it
      if (onImageSave) {
          onImageSave(downloadURL);
      }

      // Return the URL in the case that this was called via the ref
      return downloadURL;

  } catch (error) {
      logger.error('Error saving image:', error);
      return null; // Explicitly return null in case of an error
  }
};

  // this function allows a parent component to call the handleSave
  // function and receive the download URL, used in the case
  // where the parent wantst to control submit instead of
  // current screen
  useImperativeHandle(ref, () => ({
    handleSave,
  }));

  return (
    <div>
    <label className="block text-lg font-bold mb-2">{label}</label>
    <div className="relative border-dashed border-2 border-gray-400 rounded-md text-center">
    {uploading ? (
          <div className="absolute inset-0 flex items-center justify-center">
            <LoadingSpinner size={6} color="text-purple-600" />
          </div>
        ) : localImageSrc ? (
      <>
        {/* Render ImagePreview with all necessary props */}
        <ImagePreview
          localImageSrc={localImageSrc}  // Use the uploaded imageSrc
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          cropShape={cropShape}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          handleImageRemove={handleImageRemove}
        />
      </>
    ) : (
      <ImageUploadButton handleImageChange={handleImageChange} />
    )}
  </div>
  {/* Pass the necessary props to ImageStats */}
  <ImageStats
    croppedAreaPixels={croppedAreaPixels}
    recommendedResolution={recommendedResolution}
    zoom={zoom}
    debugStats={false}
  />
      {/* Render ImageUploadControls with the necessary props */}
      <ImageUploadControls
      zoom={zoom}
      handleZoomChange={setZoom}
      handleSave={handleSave}
      allowSave={allowSave}
      onReset={handleReset}
      localImageSrc={localImageSrc}
    />
  </div>
  );
});

export default ImageUpload;