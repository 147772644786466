import { useState } from 'react';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { updateProfile } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebaseConfig';
import logger from '../utils/logger';
import useCurrentUser from './useCurrentUser'; // Import your custom hook to get the current user


/**
 * Get storage path based on image type.
 *
 * @param {string} imageType - The type of image (profile, event, community)
 * @param {string} imageId - The ID related to the image type (userId, eventId, communityId)
 * @param {Object} currentUser - The current authenticated user object
 * @returns {string} - The storage path
 */
const getPath = (imageType, imageId) => {
  switch (imageType) {
    case 'profile':
      return `profile-images/${imageId}`; // imageId is userId for profile images
    case 'event':
      return `event-images/${imageId}`; // imageId is eventId for event images
    case 'community':
      return `community-images/${imageId}`; // imageId is communityId for community images
    default:
      throw new Error('Invalid image type');
  }
};

/**
 * Upload a new image file.
 *
 * @param {File} image - The image file to be uploaded
 * @param {string} imageType - The type of image (profile, event, community)
 * @param {string} [id] - The ID related to the image type (userId, eventId, communityId)
 * @param {Object} currentUser - The current authenticated user object
 * @param {Function} setError - Function to set the error state
 * @param {Function} setUploading - Function to set the uploading state
 * @returns {Promise<string>} - The download URL of the uploaded image
 */
export const uploadImage = async (image, imageType, imageId, setError, setUploading) => {
  if (!image) {
    throw new Error('No image provided');
  }
  if (!imageId) {
    throw new Error('No ID provided for the image');
  }

  try {
    const storageRef = ref(storage, `${getPath(imageType, imageId)}/${image.name}`);
    const uploadTask = uploadBytesResumable(storageRef, image);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        null,
        (error) => {
          logger.error('Error uploading image:', error);
          setError('Error uploading image');
          setUploading(false);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            setUploading(false);
            resolve(downloadURL);
          } catch (error) {
            logger.error('Error updating Firestore with image URL:', error);
            setError('Error updating Firestore with image URL');
            setUploading(false);
            reject(error);
          }
        }
      );
    });
  } catch (error) {
    logger.error('Error uploading image:', error);
    setError('Error uploading image');
    setUploading(false);
    throw error;
  }
};

/**
 * Custom hook for uploading images.
 *
 * @returns {Object} Upload handler state and functions
 */
const useImageUpload = () => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const currentUser = useCurrentUser(); // Retrieve the current user here

  const startUpload = async (image, imageType, imageId) => {
    setUploading(true);
    setError(null);
  
    try {
      const downloadURL = await uploadImage(image, imageType, imageId, setError, setUploading);
      setUploading(false);
      return downloadURL;
    } catch (error) {
      setError(error.message);
      setUploading(false);
      throw error;
    }
  };

  const removeImage = async (imageType) => {
    if (!currentUser) {
      throw new Error('No user provided');
    }

    const userId = currentUser.uid;

    try {
      const memberRef = doc(db, 'members', userId);

      // Fetch the current member document to get the profilePictureUrl
      const memberDoc = await getDoc(memberRef); // Use getDoc to fetch the document
      if (!memberDoc.exists()) {
        throw new Error('Member document not found');
      }
      const memberData = memberDoc.data();
      const profilePictureUrl = memberData.profilePictureUrl;

      // Update the member document to set profilePictureUrl to an empty string
      await updateDoc(memberRef, { profilePictureUrl: '' });

      // Delete the existing profile picture file if it exists
      if (profilePictureUrl) {
        const storageRef = ref(storage, profilePictureUrl);
        await deleteObject(storageRef);
        logger.log('Profile picture file deleted from storage');
      }
    } catch (error) {
      logger.error('Error removing profile picture:', error);
      throw error;
    }
  };

  const startRemove = async (imageType) => {
    setUploading(true);
    setError(null);

    try {
      await removeImage(imageType, currentUser);
      setUploading(false);
    } catch (error) {
      setError(error.message);
      setUploading(false);
      throw error;
    }
  };

  return { startUpload, startRemove, uploading, error };
};

export default useImageUpload;