import React, { useEffect } from 'react';
import logger from '../../../utils/logger';

const SuccessPage = () => {
    useEffect(() => {
        logger.log("SuccessPage mounted");
        if (window.opener) {
            logger.log("Posting message to parent window...");
            window.opener.postMessage({ type: 'MEETUP_AUTH_SUCCESS' }, window.location.origin);
            logger.log("Message posted to parent window, closing window...");
            window.close();
        } else {
            logger.log("No parent window found.");
        }
    }, []);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-green-100">
            <div className="bg-white p-6 rounded-lg shadow-lg">
                <h1 className="text-2xl font-bold text-green-700 mb-4">Authentication Successful!</h1>
                <p>You can close this window and refresh your screen.</p>
            </div>
        </div>
    );
};

export default SuccessPage;