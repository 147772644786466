import React, { useState, forwardRef } from 'react';

const TextInput = forwardRef(({ label, name, register, required = false }, ref) => {
    return (
        <div>
            <label className="block text-lg font-bold mb-2" htmlFor={name}>{label}</label>
            
            {/* Visible input element associated with form submission */}
            <input
                type="text"
                id={name}
                name={name}
                autoComplete="off"
                ref={ref} // Forward the ref to the visible input
                className="w-full p-2 border border-gray-300 rounded-md"
                {...register(name, { required: required ? 'Community requires a name.' : false })}
            />
        </div>
    );
});

export default TextInput;