import React, { useState, useEffect, useRef } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import logger from '../../../utils/logger';
import useFetchMember from '../../../hooks/useFetchMember';
import ReusableButton from '../../common/reusable-button/ReusableButton';

const UserProfileDropDown = () => {
    const { currentUser, logout, setIsAdmin } = useAuth();
    const navigate = useNavigate();
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const { member } = useFetchMember(currentUser ? currentUser.uid : null);

    const handleToggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (currentUser) {
            currentUser.getIdTokenResult().then(idTokenResult => {
                setIsAdmin(!!idTokenResult.claims.admin);
            });
        }
    }, [currentUser, setIsAdmin]);

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/');
            setShowDropdown(false); // Close the dropdown after logging out
        } catch (error) {
            logger.error("Failed to log out", error);
        }
    };

    const handleViewProfile = () => {
        navigate('/profile');
        setShowDropdown(false); // Close the dropdown after navigating to profile
    };

    return (
        <div className="relative flex items-center pl-4 pr-4" ref={dropdownRef}>
            {member && member.profilePictureUrl ? (
                <img
                    src={member.profilePictureUrl}
                    alt="Profile"
                    className="w-8 h-8 rounded-full border border-gray-300 cursor-pointer"
                    onClick={handleToggleDropdown}
                />
            ) : (
                <FaUserCircle className="text-2xl cursor-pointer text-purple-600" onClick={handleToggleDropdown} />
            )}
            {showDropdown && (
                <div className="absolute top-full right-0 mt-2 bg-white border border-gray-200 shadow-lg rounded-lg z-10 w-64 p-4">
                    {currentUser ? (
                        <>
                            <div className="flex items-center mb-4">
                                {member && member.profilePictureUrl ? (
                                    <img
                                        src={member.profilePictureUrl}
                                        alt="Profile"
                                        className="w-10 h-10 rounded-full border border-gray-300 mr-4"
                                    />
                                ) : (
                                    <FaUserCircle className="text-3xl text-gray-600 mr-4" />
                                )}
                                <div className="flex flex-col space-y-1 max-w-[150px]">
                                    {member && member.displayName && (
                                        <p className="text-gray-800 text-sm font-bold truncate">
                                            {member.displayName}
                                        </p>
                                    )}
                                    <p className="text-gray-600 text-sm truncate" title={currentUser.email}>
                                        {currentUser.email}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col space-y-2">
                                <ReusableButton onClick={handleViewProfile}>
                                    View Profile
                                </ReusableButton>
                                <ReusableButton onClick={handleLogout}>
                                    Logout
                                </ReusableButton>
                            </div>
                        </>
                    ) : (
                        <div className="flex flex-col space-y-2">
                            <ReusableButton onClick={() => navigate('/login')} className="bg-purple-600 text-white">
                                Login
                            </ReusableButton>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default UserProfileDropDown;