import { useState } from 'react';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const useAddCommunity = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const addCommunity = async (data, memberId) => {
    console.log('Starting addCommunity process...');
    console.log('Data:', data);
    console.log('Member ID:', memberId);
    setLoading(true);
    try {
      // Add the community document
      console.log('Adding community document to Firestore...');
      const communityRef = await addDoc(collection(db, 'communities'), {
        name: data.name,
        description: data.description,
        status: data.status,
        imageUrl: data.imageUrl,
        ownerId: memberId,
        createdAt: new Date(),
      });
      const communityId = communityRef.id;
      console.log('Community document added with ID:', communityId);

      // Construct the document ID for community_members
      const connectionDocId = `${communityId}_${memberId}`;

      // Add the owner as a host in community_members
      console.log('Adding host to community_members...');
      await setDoc(doc(db, 'community_members', connectionDocId), {
        communityId,
        memberId,
        role: 'host',
        createdAt: new Date(),
      });
      console.log('Host added to community_members with ID:', connectionDocId);

      setLoading(false);
      console.log('addCommunity process completed successfully.');
      return communityId;
    } catch (err) {
      console.error('Error adding community:', err.message);
      console.error('Error stack trace:', err.stack);
      setError(err);
      setLoading(false);
      return null;
    }
  };

  return { addCommunity, loading, error };
};

export default useAddCommunity;