import React, { useEffect } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import useFetchMember from '../../../hooks/useFetchMember'; // Adjust the path as needed

const CommunityOwner = ({ community }) => {
  const navigate = useNavigate();
  const isMeetup = community.status === 'MEETUP.COM';
  const { member, loading, error, refetch } = useFetchMember(community.ownerId); // Fetch the owner member data

  useEffect(() => {
    if (community.ownerId) {
      refetch(); // Fetch the member data when ownerId changes
    }
  }, [community.ownerId, refetch]);

  const handleClick = () => {
    navigate(`/member/${community.ownerId}`); // Navigate to the member details screen using the correct uid
  };

  const organizerName = isMeetup
    ? community.organizerName
    : loading
    ? 'Loading...'
    : error || !member
    ? 'Unknown Member'
    : member.displayName || 'Unnamed Member';

  const profilePictureUrl = isMeetup
    ? null
    : member?.profilePictureUrl || '';

  return (
    <div className="text-gray-700 mb-4 flex items-center justify-center lg:justify-start">
      <strong className="font-bold mr-2">Owner:</strong>
      {!isMeetup && member && (
        <div
          className="flex items-center cursor-pointer" // Ensures the entire div is clickable
          onClick={handleClick}
          role="button"
          tabIndex={0} // Make it focusable
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleClick(); // Handle Enter key for accessibility
          }}
        >
          {profilePictureUrl ? (
            <img
              src={profilePictureUrl}
              alt={organizerName}
              className="w-8 h-8 rounded-full mr-2 cursor-pointer" // Ensures the image is clickable
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = ''; // Fall back to default icon
              }}
            />
          ) : (
            <FaUserCircle className="w-8 h-8 mr-2 text-gray-400 cursor-pointer" /> // Ensures the icon is clickable
          )}
          <span
            className="hover:underline"
            style={{ cursor: 'pointer', color: 'inherit' }} // Ensures the text is clickable and underlines on hover
          >
            {organizerName}
          </span>
        </div>
      )}
      {isMeetup && (
        <div className="flex items-center">
          <FaUserCircle className="w-8 h-8 mr-2 text-gray-400" />
          {organizerName}
        </div>
      )}
    </div>
  );
};

export default CommunityOwner;