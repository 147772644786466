// src/components/screens/communities/CommunityScreenButtons.js
import React from 'react';
import ScreenButton from '../../common/screen-button/ScreenButton';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';

const CommunityScreenButtons = ({ activeTab, setActiveTab }) => {
  const { currentUser, isAdmin } = useAuth();

  return (
    <div className="flex justify-end space-x-4 mb-4">
      <ScreenButton
        onClick={() => setActiveTab('all-communities')}
        active={activeTab === 'all-communities'}
      >
        All Communities
      </ScreenButton>
      {currentUser && (
        <>
          <ScreenButton
            onClick={() => setActiveTab('my-communities')}
            active={activeTab === 'my-communities'}
          >
            My Communities
          </ScreenButton>
          {isAdmin && (
            <Link to="/new-community">
              <ScreenButton>
                + New Community
              </ScreenButton>
            </Link>
          )}
        </>
      )}
    </div>
  );
};

export default CommunityScreenButtons;