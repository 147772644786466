// src/utils/utils.js

export const parseDuration = (duration) => {
    if (!duration) return 'Unknown duration';

    const customFormats = {
        '1 hour': '1 hour',
        '1.5 hours': '1.5 hours',
        '2 hours': '2 hours',
        '3 hours': '3 hours'
    };

    if (customFormats[duration]) {
        return customFormats[duration];
    }

    // Handle other formats like ISO 8601
    const regex = /PT(?:(\d+)H)?(?:(\d+)M)?/;
    const matches = duration.match(regex);
    if (!matches) return 'Invalid duration format';

    const hours = matches[1] ? `${matches[1]} hour${matches[1] > 1 ? 's' : ''}` : '';
    const minutes = matches[2] ? `${matches[2]} minute${matches[2] > 1 ? 's' : ''}` : '';
    return `${hours} ${minutes}`.trim();
};