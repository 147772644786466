// src/components/common/form-components/StatusSelect.js

import React from 'react';

const StatusSelect = ({ register, label, options = [] }) => (
  <div>
    <label className="block text-lg font-bold mb-2">{label}
      <span className="ml-2 text-sm text-gray-500" title="Draft: Not live and only saved for the user. Hidden: Only invited people can see it. Private: Listed in the app, but detailed info requires joining. Public: Everyone can find, see, and join.">[?]</span>
    </label>
    <select
      {...register('status', { required: true })}
      className="w-full p-2 border border-gray-300 rounded-md"
    >
      {options.map((option, index) => (
        <option key={index} value={option.value}>{option.label}</option>
      ))}
    </select>
  </div>
);

export default StatusSelect;