import React from 'react';
import { FaUserCircle } from 'react-icons/fa';

const OwnerInfo = ({ ownerInfo }) => (
  <div>
    <label className="block text-gray-700 font-bold mb-2">Owner</label>
    <div className="flex items-center">
      {ownerInfo?.profilePictureUrl ? (
        <img
          src={ownerInfo.profilePictureUrl}
          alt={ownerInfo.displayName || ownerInfo.email}
          className="w-10 h-10 rounded-full mr-4 border border-gray-300"
        />
      ) : (
        <FaUserCircle className="w-10 h-10 text-gray-500 mr-4" />
      )}
      <span>{ownerInfo?.displayName || ownerInfo?.email}</span>
    </div>
  </div>
);

export default OwnerInfo;