// src/components/StaticContent/BecomeMember.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
//import '../../styles/StaticContent.css';

const BecomeMember = () => {
    const navigate = useNavigate();

    const handleSubscribeClick = () => {
        navigate('/signup');
    };

    return (
        <div className="static-content-container">
            <h2>Become a Member</h2>
            <p>Join the Reading Philosophy and Psychology Circle as a member to support our mission and gain access to exclusive events.</p>
            <p>Your membership helps us cover the costs of organizing events and compensating our volunteer organizers. Choose from a monthly or annual subscription:</p>
            <ul>
                <li>Monthly Membership: $7.95/month</li>
                <li>Annual Membership: $79.95/year</li>
            </ul>
            <p>As a member, we plan to offer:</p>
            <ul>
                <li>Access to exclusive events</li>
                <li>Priority registration for popular events</li>
                <li>Special member-only content and discussions</li>
                <li>Opportunities to connect with other members</li>
            </ul>
            <p>If you'd like to contribute beyond the membership, you can also make a one-time donation.</p>
            <button onClick={handleSubscribeClick}>Subscribe Now</button> {/* Integrate with your Stripe subscription process */}
            <button>Donate</button> {/* Integrate with your donation process */}
        </div>
    );
};

export default BecomeMember;